import { Injectable } from '@angular/core';
import { Uris } from '../public/Uris';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { GlobalFunction } from '../public/globalFunction';

@Injectable({
  providedIn: 'root',
})
export class InformsService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}

  generateInform(query: any, type: string) {
    return this.http
      .get(`${Uris.INFORMS}generate_inform_${type}/${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getInforms(query: any) {
    return this.http
      .get(`${Uris.INFORMS}${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getInform(id) {
    return this.http
      .get(`${Uris.INFORMS}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  deleteInform(id) {
    return this.http
      .delete(`${Uris.INFORMS}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }







  getOneInformePDF(id) {
    return this.http.get(`${Uris.INFORMS}${id}/`, this.PDF()).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err: any) => {
        return this.GlobalFunction.validateError(err);
      })
    );
  }

  private PDF() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      'Accept-language': 'en-es',
      Authorization: `Token ${this.leerToken()}`,
    });
    return { headers: headers };
  }
  leerToken() {
    return localStorage.getItem('token');
  }
}
