import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MenuService } from './core/menu/menu.service';
import { SettingsService } from './core/settings/settings.service';
import { AdminAgronodo } from './Services/agronodo/admin-agronodo.service';
import { NgwWowService } from 'ngx-wow';
import { Menu } from './routes/menu';
import { Subscription } from 'rxjs';
import { StorageService } from './Services/shared/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  modeSubsciption: Subscription;
  constructor(
    public settings: SettingsService,
    public route: Router,
    public menuService: MenuService,
    public profile: AdminAgronodo,
    private wowService: NgwWowService,
    public storageService: StorageService
  ) {
    this.wowService.init();

    this.route.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        let menu = Menu.menu();
        this.menuService.push(menu);
      }
    });
  }
  ngOnInit(): void {
    this.modeSubsciption = this.storageService
      .watchStorage('mode')
      .subscribe(async (mode) => {
        let menu = Menu.menu();
        this.menuService.push(menu);
      });
  }
}
