export class Menu {
  // ADMIN = (1, _('Admin'))
  // AGRONODO = (2, 'Agronodo')
  // ADMIN_AGRONODO = (3, _('Admin agronodo'))
  // AGRICOLA = (4, 'Agricola')
  // ADMIN_AGRICOLA = (5, _('Admin agricola'))
  // ADMIN_ENGINEER = (6, _('Admin supervisor'))
  // ENGINEER = (7, _('Supervisor'))
  // SPECIALIST = (8, _('specialist'))
  // AGENCY = (9, _('Agency'))

  // MENU TYPES
  // NORMAL = 1
  // SUBMENU = 2

  public static menu() {
    // Agricola
    const Home_agricola = {
      text: 'menu.home',
      link: '/agricola/home',
      icon: 'fa fa-home ',
      type: 1,
    };

    const Usuarios_agricola = {
      text: 'menu.users',
      icon: 'fa fa-users',
      type: 2,
      submenu: [
        { text: 'menu.admin_user', link: '/agricola/admin' },
        {
          text: 'menu.user_supervisor',
          link: '/agricola/supervisores',
        },
        { text: 'menu.user_monitor', link: '/agricola/monitor' },
      ],
    };

    const agricola_admin = {
      text: 'menu.admins_agronodo',
      icon: 'fa fa-user-secret',
      link: '/agricola/admin',
      type: 1,
    };

    const agricola_supervisors = {
      text: 'menu.user_supervisor',
      icon: 'fa fa-user-md',
      link: '/agricola/supervisores',
      type: 1,
    };

    const agricola_monitors = {
      text: 'menu.user_monitor',
      icon: 'fa fa-users',
      link: '/agricola/monitor',
      type: 1,
    };

    const Analisis_datos = {
      text: 'menu.data_analisis',
      icon: 'fas fa-crosshairs',
      disabled: true,
      type: 2,
      submenu: [
        {
          text: 'menu.data_graphs',
          link: '/agricola/datos_generales',
          icon: 'fas fa-chart-bar',
        },
        {
          text: 'menu.heat_map',
          link: '/agricola/mapa_calor',
          icon: 'fas fa-fire-alt',
        },
        {
          text: 'menu.personal_data',
          link: '/agricola/datos_personales',
          icon: 'fas fa-database',
        },
      ],
    };

    const Documents = {
      text: 'menu.documents',
      icon: 'fas fa-file',
      disabled: false,
      type: 2,
      submenu: [
        {
          text: 'menu.informs',
          link: '/agricola/informes',
        },
        {
          text: 'menu.requisitions',
          link: '/agricola/documentos',
        },
      ],
    };

    const Lotes_agricola = {
      text: 'menu.fields',
      link: '/agricola/lotes',
      icon: 'fa fa-layer-group',
      type: 1,
    };

    const Tareas_agricola = {
      text: 'menu.tasks',
      link: '/agricola/tareas',
      icon: 'fas fa-tasks',
      type: 1,
    };

    const Incidencias_agricola = {
      text: 'menu.incidences',
      link: '/agricola/incidencias',
      icon: 'fas fa-viruses',
      type: 1,
    };

    const Individual_reports = {
      text: 'menu.individual',
      link: '/agricola/reportes_individuales',
      icon: 'fas fa-map-marker',
      disabled: false,
      type: 1,
    };

    const Cycle = {
      text: 'menu.cycles',
      link: '/agricola/cycles',
      icon: 'fa fa-calendar',
      type: 1,
    };

    const Settings = {
      text: 'menu.settings',
      link: '/agricola/settings',
      icon: 'fa fa-cog',
      type: 1,
    };
  
    // Agronodo
    const Home_agronodo = {
      text: 'menu.home',
      link: '/agronodo/home',
      icon: 'fas fa-home',
      type: 1,
    };
    const Especialista = {
      text: 'menu.specialist',
      link: '/agronodo/especialistas',
      icon: 'fas fa-user-tie',
      type: 1,
    };
    const Agencia = {
      text: 'menu.agency',
      link: '/agronodo/agencias',
      icon: 'fas fa-building',
      type: 1,
    };
    const Admin_agronodo = {
      text: 'menu.admins_agronodo',
      link: '/agronodo/admin',
      icon: 'fas fa-user-shield',
      type: 1,
    };
    const Agricola_agronodo = {
      text: 'menu.agricolas_agronodo',
      link: '/agronodo/agricolas',
      icon: 'fas fa-users',
      type: 1,
    };
    const Tipo_cultivos = {
      text: 'menu.crop_type',
      link: '/agronodo/cultivos',
      icon: 'fas fa-seedling',
      type: 1,
    };
    const Incidencias = {
      text: 'menu.incidences',
      link: '/agronodo/incidencias',
      icon: 'fas fa-viruses',
      type: 1,
    };

    //Agency
    const Home_agency = {
      text: 'menu.home',
      link: '/agency/home',
      icon: 'fa fa-home ',
      type: 1,
    };

    const Agency_acceses = {
      text: 'menu.acceses',
      link: '/agency/acceses',
      icon: 'fas fa-sign-in-alt',
      type: 1,
    };

    const Agency_specialists = {
      text: 'menu.specialist',
      link: '/agency/specialists',
      icon: 'fas fa-user-tie',
      type: 1,
    };

    const Agency_agricolas = {
      text: 'menu.agricolas_agronodo',
      link: '/agency/agricolas',
      icon: 'fas fa-users',
      type: 1,
    };

    //Specialist
    const Home_specialist = {
      text: 'menu.home',
      link: '/specialist/home',
      icon: 'fa fa-home ',
      type: 1,
    };

    const Specialist_acceses = {
      text: 'menu.acceses',
      link: '/specialist/acceses',
      icon: 'fas fa-sign-in-alt',
      type: 1,
    };

    //Shared
    const Notifications = {
      text: 'menu.notifications',
      link: '/shared/notificaciones',
      icon: 'fas fa-bell',
      disabled: false,
      type: 1,
    };

    const Recursos = {
      text: 'menu.resources',
      link: '/shared/recursos',
      icon: 'fa fa-book',
      type: 1,
    };

    var role: any[] = [];
    const validateUser = () => {
      let USER = <any>JSON.parse(localStorage.getItem('USER') || null);
      let access = <any>JSON.parse(localStorage.getItem('access') || null);
      let mode = Number(localStorage.getItem('mode'));
      if (mode === 0 && USER) {
        return USER;
      }
      if (mode === 1 && access) {
        return access;
      }
      return null;
    };
    let user = validateUser();
    if (!user) {
      role = [Home_agricola, Admin_agronodo, Agricola_agronodo];
    } else {
      switch (user.user_type) {
        //Agronodo
        case 1: {
          role = [
            Home_agronodo,
            Admin_agronodo,
            Agricola_agronodo,
            Especialista,
            Agencia,
            Tipo_cultivos,
            Incidencias,
            Recursos,
          ];
          break;
        }
        //Agronodo
        case 2: {
          role = [
            Home_agronodo,
            Admin_agronodo,
            Agricola_agronodo,
            Especialista,
            Agencia,
            Tipo_cultivos,
            Incidencias,
            Recursos,
          ];
          break;
        }
        //Admin Agronodo
        case 3: {
          role = [
            Home_agronodo,
            Agricola_agronodo,
            Especialista,
            Agencia,
            Tipo_cultivos,
            Incidencias,
            Recursos,
          ];
          break;
        }
        // Agricola
        case 4: {
          role = [
            Home_agricola,
            user.profile.prms_fields_bool ? Lotes_agricola : { disabled: true },
            user.profile.prms_users_extra_bool
              ? Usuarios_agricola
              : { disabled: true },
            Tareas_agricola,
            Incidencias_agricola,
            Documents,
            Notifications,
            Analisis_datos,
            Recursos,
            Individual_reports,
            Cycle,
            Settings
          ];
          break;
        }
        //Admin Agricola
        case 5: {
          role = [
            Home_agricola,
            user.profile.prms_fields_bool ? Lotes_agricola : { disabled: true },
            user.profile.prms_users_extra_bool && user.profile.prms_admin_bool
              ? agricola_admin
              : { disabled: true },
            user.profile.prms_users_extra_bool &&
            user.profile.prms_supervisor_bool
              ? agricola_supervisors
              : { disabled: true },
            user.profile.prms_users_extra_bool
              ? agricola_monitors
              : { disabled: true },
            user.profile.prms_tasks_bool ? Tareas_agricola : { disabled: true },
            Documents,
            Incidencias_agricola,
            Notifications,
            Analisis_datos,
            Recursos,
            Individual_reports,
            Cycle,
            Settings
          ];
          break;
        }
        //Supervisor
        case 6: {
          role = [
            Home_agricola,
            user.profile.prms_fields_bool ? Lotes_agricola : { disabled: true },
            user.profile.prms_tasks_bool ? Tareas_agricola : { disabled: true },
            user.profile.prms_users_extra_bool && user.profile.prms_monitors_bool
              ? agricola_monitors
              : { disabled: true },
            user.profile.prms_reports_bool
              ? Individual_reports
              : { disabled: true },
            Analisis_datos,
            Documents,
            Incidencias_agricola,
            Notifications,
            Recursos,
          ];
          break;
        }
        //Especialista
        case 8: {
          role = [Home_specialist, Specialist_acceses, Recursos];
          break;
        }
        //Agencia
        case 9: {
          role = [
            Home_agency,
            Agency_acceses,
            Agency_specialists,
            Agency_agricolas,
            Recursos,
          ];
          break;
        }
        default: {
          break;
        }
      }
      return role;
    }
    return true;
  }
}
