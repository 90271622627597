<div
  class="card d-flex flex-column align-items-center justify-content-center wow fadeInDown"
  [ngClass]="detail ? '' : 'h-100'"
>
  <div class="card-body w-100 px-3 center">
    <div class="row" style="width: inherit;">
      <div
        class="col-lg-7 col-sm-12 text-incidencia"
        style="text-align: justify"
      >
        <ng-container *ngIf="agricultural">
          <h4 class="mb-0">
            {{
              incidence.agricultural_incidence?.name?.name
                ? incidence.agricultural_incidence?.name?.name
                : "??"
            }}
          </h4>
          <div class="d-flex">
            <small
              >{{ "incidences.type_incidence" | translate }}:
              <b>{{ "generals.type_agri" | translate }}</b></small
            >
          </div>
        </ng-container>
        <ng-container *ngIf="!agricultural">
          <h4 class="mb-0">
            {{
              incidence.technical_incidence.name.name
                ? incidence.technical_incidence.name.name
                : "??"
            }}
          </h4>
          <div class="d-flex">
            <small
              >{{ "incidences.type_incidence" | translate }}:
              <b>{{ "generals.type_tech" | translate }}</b></small
            >
          </div>
        </ng-container>

        <div class="d-flex align-items-center justify-content-between my-2">
          <small
            >ID: <b>{{ incidence.id ? incidence.id : "??" }}</b></small
          >
          <small
            >{{ "table.date" | translate }}:
            <b>{{ incidence.datetime | date : " dd/MM/yyyy" }}</b></small
          >
        </div>
        <small
          ><b>{{ "generals.description" | translate }}: </b
          >{{ incidence.description ? incidence.description : "??" }}</small
        >
      </div>
      <div class="col-lg-5 col-sm-12 d-flex justify-content-center">
        <div class="row justify-content-center">
          <div class="col-12 flex-column center">
            <ng-container *ngIf="!agricultural">
              <img
                alt="tecnica"
                src="assets/img/icons/tecnica.png"
                [style.width]="detail ? '45%' : '100px'"
              />
            </ng-container>

            <ng-container *ngIf="agricultural">
              <img
                alt="agricola"
                [src]="
                  imagePlant(
                    incidence.agricultural_incidence?.fruit,
                    incidence.agricultural_incidence?.plant,
                    incidence.agricultural_incidence?.ground
                  )
                "
                [style.width]="detail ? '45%' : '100px'"
              />
            </ng-container>
            <div class="row">
              <div
                class="col-12 p-0 text-center"
                *ngIf="agricultural && incidence.agricultural_incidence?.amount"
              >
                <small
                  >{{ "incidences.amount" | translate }}:
                  <b>{{
                    incidence.agricultural_incidence?.amount
                      ? incidence.agricultural_incidence?.amount
                      : "??"
                  }}</b></small
                >
              </div>
            </div>
          </div>

          <div class="col-12 center p-0" *ngIf="agricultural">
            <div class="d-flex flex-row">
              <div
                *ngFor="let item of agriculturalIncidenceItems"
                class="icon d-flex flex-column mx-1 text-center"
              >
                <ng-container *ngIf="item.value">
                  <a
                    class="fas fa-2x fa-square"
                    [ngStyle]="{ color: color(item.value) }"
                  ></a>
                  <small>{{ item.label | translate }}</small>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    *ngIf="!detail"
    type="button"
    class="btn btn-primary w-100 custom-btn"
    [routerLink]="['/agricola/incidencia', incidence.id]"
  >
    {{ "generals.more" | translate }}
  </button>
</div>
