<div class="row">
  <div class="col-12" *ngIf="showCropp">
    <div class="settings-menu">
      <em
        [class]="containWithinAspectRatio ? 'fa fa-arrows-alt' : 'fa fa-expand'"
        (click)="toggleContainWithinAspectRatio()"
      ></em>
      <em class="fa fa-undo" (click)="rotateLeft()"></em>
      <em class="fa fa-undo voltear" (click)="rotateRight()"></em>
      <em class="fa fa-sort horizontal" (click)="flipHorizontal()"></em>
      <em class="fa fa-sort" (click)="flipVertical()"></em>
      <em class="fa fa-search-minus" (click)="zoomOut()"></em>
      <em class="fa fa-search-plus" (click)="zoomIn()"></em>
    </div>
  </div>
  <div [class]="showCropp ? 'col-lg-6 col-sm-12' : 'col-12'" class="center flex-column">
    <label
      class="d-flex justify-content-center align-items-center"
      for="labelLogo"
      [class]="size == 1 ? 'label-size-1' : 'label-size-2'"
    >
      <img
        [src]="returnImage()"
        class="img-fluid label-size img-thumbnail"
        [class]="{'rounded-circle': rounded, 'frontpage-thumbnail': fontPage, 'square-thumbnail': !fontPage}"
        alt="logo"
      />
    </label>
    <input
      id="labelLogo"
      (change)="fileChangeListener($event)"
      accept=".png, .jpg, .jpeg"
      type="file"
      style="display: none"
    />
    <small> {{'generals.preview' | translate}} </small>
  </div>
  <div [class]="showCropp ? 'col-lg-6 col-sm-12 center' : 'col-12 d-none'">
    <image-cropper
      style="width: 250px; height: 250px"
      class="align-items-center justify-content-center"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [style.display]="showCropp ? null : 'none'"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="1 / 1"
      format="png"
      (imageLoaded)="imageLoaded()"
      (LoadedImage)="LoadedImage($event)"
      [onlyScaleDown]="true"
      [alignImage]="'left'"
      [roundCropper]="false"
      (imageCropped)="imageCropped($event)"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
    >
    </image-cropper>
    <div *ngIf="!imageChangedEvent" class="text-center">
      <small>{{'general.click-preview' | translate}}.</small>
    </div>
  </div>
</div>
