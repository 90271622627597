import { HttpClient } from '@angular/common/http';
import { GlobalFunction } from './../public/globalFunction';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public storageObs = {
    mode: new EventEmitter<String>(),
  };

  watchStorage(key: 'mode'): Observable<any> {
    return this.storageObs[key];
  }

  constructor(
    public router: Router,
    private http: HttpClient,
    public globalFunction: GlobalFunction
  ) {}

  setItem(key: string, data: any) {
    this.storageObs[key].emit(data);
  }

  getProfileData() {
    const userDataString = localStorage.getItem('USER');

    if (userDataString) {
      return JSON.parse(userDataString);
    }
  }

  isAdmin() {
    let localUser: string | null = localStorage.getItem('USER');
    let user: any = localUser ? <any>JSON.parse(localUser) : '';
    if (user) {
      if (
        user.user_type === 1 ||
        user.user_type === 2 ||
        user.user_type === 3
      ) {
        return true;
      }
    }
    return false;
  }
  validateUrl = () => {
    const validateUser = () => {
      let USER = <any>JSON.parse(localStorage.getItem('USER') || null);
      let access = <any>JSON.parse(localStorage.getItem('access') || null);
      let mode = Number(localStorage.getItem('mode'));
      if (!mode && USER) {
        return { ...USER };
      }
      if (mode === 1 && access) {
        return { ...access };
      }
      return { ...USER };
    };
    const user = validateUser();

    if (user) {
      const { user_type } = user;
      if (user_type === 1 || user_type === 2 || user_type === 3) {
        this.router.navigateByUrl('/agronodo/home');
      }
      if (user_type === 4 || user_type === 5 || user_type === 6) {
        this.router.navigateByUrl('/agricola/home');
      }
      if (user_type === 8) {
        this.router.navigateByUrl('/specialist/home');
      }
      if (user_type === 9) {
        this.router.navigateByUrl('/agency/home');
      }
    }
  };
}
