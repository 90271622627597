export const environment = {
  production: false,
  LOCAL_API_ENDPOINT_V1: 'http://127.0.0.1:7000/api/v1/',
  LOCAL_API_ENDPOINT_V2: 'http://127.0.0.1:7000/v2/',
  LOCAL_SOCKET_URL: `ws://127.0.0.1:7000/ws/main-socket/`,
  LOCAL_API_ENDPOINT: `http://127.0.0.1:7000/`,
  API_ENDPOINT_V1: 'https://api.agronodo.app/api/v1/',
  API_ENDPOINT_V2: 'https://api.agronodo.app/v2/',
  API_ENDPOINT: `https://api.agronodo.app/`,
  SOCKET_URL: `ws://api.agronodo.app/ws/main-socket/`,
  apikeyMap: 'AIzaSyAtT1YCtBX1-0hMmbmld0227CgumVG_qhs',
};
