import { Injectable } from '@angular/core';
import {
  Saludablereporte,
  reportNormal,
  alerta,
  peligro,
  sano,
  TaskPendiente,
  TaskIncidencePendiente,
} from './icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SettingsService } from '../../core/settings/settings.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
declare var google: any;

interface Coordinates {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalFunction {
  constructor(
    public Settings: SettingsService,
    public toastr: ToastrService,
    public router: Router,
    public modalService: NgbModal
  ) {}

  headers() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-language': 'es',
      Authorization: `Bearer ${this.leerToken()}`,
    });
    return { headers: headers };
  }

  publicHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-language': 'es',
    });
    return { headers: headers };
  }

  headersMultipart() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.leerToken()}`,
    });
    return { headers: headers };
  }

  leerToken() {
    const token = localStorage.getItem('token');
    const access = <any>JSON.parse(localStorage.getItem('access'));
    const mode = Number(localStorage.getItem('mode'));

    if (!mode && token) {
      return token;
    }
    if (mode === 1 && access) {
      return access.token;
    }
    return token;
  }

  validateSoloAdmin() {
    let localUser: string | null = localStorage.getItem('USER');
    let user: any = localUser ? <any>JSON.parse(localUser) : '';

    if (
      (user && user.user_type === 2) ||
      user.user_type === 1 ||
      user.user_type === 3
    ) {
      return true;
    } else {
      return false;
    }
  }

  menorAmayor = (value) => {
    if (value) {
      let array = value;
      array.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      });
      return array;
    }
  };

  iconsValidatorsRisk = (report) => {
    /* Esta funcion retorna la incidencia con mas riesgo */
    const { type, incidences } = report;
    switch (type) {
      case 1:
        return sano;
      case 2:
        if (!incidences || incidences.length === 0) {
          return sano;
        }
        if (incidences.length > 0) {
          let inc = incidences.sort((a, b) => {
            if (a.risk < b.risk) {
              return 1;
            }
            if (a.risk > b.risk) {
              return -1;
            }
            return 0;
          });
          if (inc.length > 0) {
            if (inc[0].agricultural_incidence) {
              let { fruit, ground, plant } = inc[0].agricultural_incidence;
              if (fruit === 1 && ground === 1 && plant === 1) {
                return Saludablereporte;
              } else if (fruit === 3 || ground === 3 || plant === 3) {
                return peligro;
              } else if (fruit === 2 || ground === 2 || plant === 2) {
                return alerta;
              }
            } else {
              return peligro;
            }
          }
        }
        return peligro;
      case 3:
        return reportNormal;
      case 4:
        if (!incidences || incidences.length === 0) {
          return reportNormal;
        }
        if (incidences.length > 0) {
          let inc = incidences.sort((a, b) => {
            if (a.risk < b.risk) {
              return 1;
            }
            if (a.risk > b.risk) {
              return -1;
            }
            return 0;
          });
          if (inc.length > 0) {
            if (inc[0].agricultural_incidence) {
              let { fruit, ground, plant } = inc[0].agricultural_incidence;
              if (fruit === 1 && ground === 1 && plant === 1) {
                return Saludablereporte;
              } else if (fruit === 3 || ground === 3 || plant === 3) {
                return peligro;
              } else if (fruit === 2 || ground === 2 || plant === 2) {
                return alerta;
              }
            } else {
              return peligro;
            }
          }
        } else {
          return peligro;
        }
    }
    return reportNormal;
  };

  ColorValidatorsCircleReport = (report) => {
    const { type, incidences } = report;

    switch (type) {
      case 1:
        return '#5cc910';
      case 2:
        if (!incidences || incidences.length === 0) {
          return '#5cc910';
        }
        if (incidences.length > 0) {
          let inc = incidences.sort((a, b) => {
            if (a.risk < b.risk) {
              return 1;
            }
            if (a.risk > b.risk) {
              return -1;
            }
            return 0;
          });
          if (inc.length > 0) {
            if (inc[0].agricultural_incidence) {
              let { fruit, ground, plant } = inc[0].agricultural_incidence;
              if (fruit === 1 && ground === 1 && plant === 1) {
                return '#5cc910';
              } else if (fruit === 3 || ground === 3 || plant === 3) {
                return '#ff0000';
              } else if (fruit === 2 || ground === 2 || plant === 2) {
                return '#ffff00';
              }
            } else {
              return '#ff0000';
            }
          }
        }
        return '#ff0000';
      case 3:
        return '#0000ff';
      case 4:
        if (!incidences || incidences.length === 0) {
          return '#5cc910';
        }
        if (incidences.length > 0) {
          let inc = incidences.sort((a, b) => {
            if (a.risk < b.risk) {
              return 1;
            }
            if (a.risk > b.risk) {
              return -1;
            }
            return 0;
          });
          if (inc.length > 0) {
            if (inc[0].agricultural_incidence) {
              let { fruit, ground, plant } = inc[0].agricultural_incidence;
              if (fruit === 1 && ground === 1 && plant === 1) {
                return '#5cc910';
              } else if (fruit === 3 || ground === 3 || plant === 3) {
                return '#ff0000';
              } else if (fruit === 2 || ground === 2 || plant === 2) {
                return '#ffff00';
              }
            } else {
              return '#ff0000';
            }
          }
        } else {
          return '#ff0000';
        }
    }
    return '5cc910';
  };

  iconsValidatorSolo = (incidence) => {
    /* Esta funcion retorna la el nivel de peligro de una sola incidencia */
    const { agricultural_incidence, technical_incidence } = incidence;

    if (agricultural_incidence) {
      let { fruit, ground, plant } = agricultural_incidence;
      if (fruit === 1 && ground === 1 && plant === 1) {
        return Saludablereporte;
      } else if (fruit === 3 || ground === 3 || plant === 3) {
        return peligro;
      } else if (fruit === 2 || ground === 2 || plant === 2) {
        return alerta;
      }
    }
    if (technical_incidence) {
      return reportNormal;
    } else {
      return peligro;
    }
  };

  iconsValidatorsTask = (value) => {
    if (value.done) {
      if (value.report.title.includes('Reporte Saludable')) {
        return sano;
      } else {
        if (!value.report.incidence) {
          return reportNormal;
        } else if (value.report.incidence.agriculturalIncidence) {
          let { fruit, ground, plant } =
            value.report.incidence.agriculturalIncidence;
          if (fruit === 1 && ground === 1 && plant === 1) {
            return Saludablereporte;
          } else if (fruit === 3 || ground === 3 || plant === 3) {
            return peligro;
          } else if (fruit === 2 || ground === 2 || plant === 2) {
            return alerta;
          }
        } else {
          return alerta;
        }
      }
    } else if (!value.done) {
      return TaskPendiente;
    }
    return TaskIncidencePendiente;
  };

  color(value) {
    let obj = {
      1: '#008f39',
      2: '#FFFF00',
      3: '#cb3234',
    };
    return obj[value] ? obj[value] : '#cb3234';
  }

  mostrarAlert = (value) => {
    setTimeout(async () => {
      await this.Settings.togleAlertTime({
        text: value.text,
        class: value.class,
      });
    });
  };

  validateCoordinates = (value) => {
    console.log(value);

    let array = value;
    if (array.length > 0 && array[0].lat !== array[array.length - 1].lat) {
      let i = array[0];
      array.push(i);
    }
    return array;
  };

  calculatedistance(point1, point2) {
    return (
      google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(point1),
        new google.maps.LatLng(point2)
      ) / 1
    );
  }

  validateError(e) {
    let { status, error, statusText } = e;

    let arrayErrors = error
      ? Object.values(error.user ? error.user : error)
      : [];
    let arrayKeysErrors = error
      ? Object.keys(error.user ? error.user : error)
      : [];

    if (status === 404) {
      this.animation('error', 'Error 404', `No encontrado`);
    } else if (status === 500) {
      this.animation('error', 'Error 500', `Server Error (500)`);
    } else {
      for (let i = 0; i < arrayErrors.length; i++) {
        if (arrayErrors[i] === true) {
          this.animation(
            'error',
            status,
            `${arrayKeysErrors[i].toLowerCase()}: ${statusText.toString()}`
          );
        } else {
          this.animation(
            'error',
            status,
            `${arrayKeysErrors[i].toLowerCase()}: ${arrayErrors[i].toString()}`
          );
        }
      }
    }

    if (error) {
      if (error.detail === 'Invalid token' || statusText === 'Unauthorized') {
        localStorage.removeItem('token');
        this.router.navigateByUrl('/login');
      }
    }
    return throwError(e);
  }

  getRandom(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  Modal = async (template, type: 'show' | 'hide', config: NgbModalOptions) => {
    if (type === 'show') {
      this.modalService.open(template, config);
    } else {
      this.modalService.dismissAll();
    }
  };

  optionSwalAlert = async (title, text, btn1, btn2) => {
    let bool = false;
    await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: btn1,
      cancelButtonText: btn2,
      confirmButtonColor: '#87c933',
      cancelButtonColor: '#e59e00',
    }).then((result) => {
      if (result.value) {
        bool = true;
      } else {
        Swal.close();
      }
    });
    return bool;
  };

  isPhone() {
    if (window.screen.width <= 768) {
      // 768px portrait
      return true;
    } else {
      return false;
    }
  }

  isSoloLot(field: any) {
    let bool = false;
    let sub;
    let subId;
    let { subfield } = field;
    if (field.coordinates) {
      if (subfield.length > 0) {
        sub = subfield[0];
        subId = subfield[0].id;
        if (
          sub.subfieldCoordinates[0].lat === field.coordinates[0].lat &&
          sub.subfieldCoordinates[0].lng === field.coordinates[0].lng
        ) {
          bool = true;
        } else {
          bool = false;
        }
      } else {
        bool = false;
      }
      return bool;
    }
    return bool;
  }

  colores(opacity) {
    return `rgba(${[
      this.getRandom(0, 255),
      this.getRandom(0, 255),
      this.getRandom(0, 255),
      opacity,
    ].join(',')})`;
  }

  converDate(value) {
    var d = new Date('0001-01-01T' + value);
    let min =
      d.getHours() === 0
        ? `${d.getMinutes()}Min. ${d.getSeconds()}s `
        : `${d.getHours()}H. ${d.getMinutes()}Min. ${d.getSeconds()}s. `;
    return min;
  }

  ValidatePolygon(value) {
    let array = value;
    if (array.length === 3) {
      let path = array[0];
      array.push(path);
    }
    if (value.length >= 4) {
      let initialLat = value[0].lat;
      for (let i = 1; i < value.length; i++) {
        if (value[i].lat === initialLat) {
          array.splice(i, 1);
        }
      }
      array.push(value[0]);
    }

    console.log(array);

    return array;
  }

  validateBack(value: any) {
    let array = value;
    if (array.length === 3) {
      array.pop();
      array.pop();
    } else if (array.length >= 4) {
      array.splice(array.length - 1, 1);
      array.pop();
      array.push(array[0]);
    } else if (array.length === 1) {
      array.pop();
      array.splice(array.length - 1, 1);
    } else if (array.length > 1) {
      array.pop();
    }

    return value;
  }

  centerPolygon(coords: Coordinates[]) {
    let lat = 0;
    let lng = 0;

    if (coords) {
      let sumLat = 0,
        sumLng = 0;

      for (let i = 0; i < coords?.length; i++) {
        let point = coords[i];
        let x = point.lat;
        let y = point.lng;
        sumLat += x;
        sumLng += y;
      }

      lat = sumLat / coords?.length;
      lng = sumLng / coords?.length;
    }

    return { lat: lat, lng: lng };
  }

  config: any = { progressBar: true };
  animation(type: any, text: string, title: string) {
    switch (type) {
      case 'success':
        this.toastr.success(title, text, this.config);
        break;
      case 'error':
        this.toastr.error(title, text, this.config);
        break;

      default:
        break;
    }
    this.toastr;
  }

  printDiv(printSection: any) {
    let popupWinindow;
    let innerContents = document.getElementById(printSection).innerHTML;

    popupWinindow = window.open(
      '',
      '_blank',
      'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
    );
    popupWinindow.document.open();
    popupWinindow.document.write(
      '<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
        innerContents +
        '</html>'
    );
    popupWinindow.document.close();
  }

  goRequisitions = (type, typeIdentifier) => {
    /* recorrido, reporte, informe,  tarea */
    if (type && typeIdentifier) {
      this.router.navigateByUrl(
        `/agricola/documentos/form/${type}/${typeIdentifier}/new`
      );
    } else {
      this.router.navigateByUrl(`/agricola/documentos/new`);
    }
  };

  getNameNotification(type) {
    switch (type) {
      case 1:
        return 'Notificaciones';
      case 10:
        return 'Cambio en la configuraciones';
      case 11:
        return 'Permisos cambiados';
      case 14:
        return 'Limite alcanzado';
      case 20:
        return 'Tareas asignadas';
      case 21:
        return 'Tareas creadas';
      case 30:
        return 'Incidencia encontrada';
      case 31:
        return 'Recorrido completado';
      default:
        break;
    }
    return '';
  }

  validateColor(color) {
    if (color) {
      let str: string = color.substring(0, color.length - 2);
      if (str.length === 7) {
        return str;
      } else {
        return '#4caf50';
      }
    } else {
      return '#4caf50';
    }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;

        return [{ lat: latitude, lng: longitude }];
      });
    }

    console.log('No support for geolocation');
    return false;
  }
}

export let Visible: Boolean;
export const customOptions: OwlOptions = {
  animateOut: 'fadeOut',
  loop: true,
  mouseDrag: true,
  autoWidth: true,
  autoHeight: true,
  touchDrag: true,
  dots: false,
  nav: true,
  margin: 0,
  navSpeed: 700,
  navText: [
    '<i class="fas fa-chevron-left"></i>',
    '<i class="fas fa-chevron-right"></i>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    740: {
      items: 3,
    },
    940: {
      items: 4,
    },
  },
};

export const customOptionsitem: OwlOptions = {
  nav: true,
  items: 1,
  loop: true,
  center: true,
  margin: 0,
  lazyLoad: true,
  dots: false,
};
