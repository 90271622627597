import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { AgencyGuard } from '../guard/agency.guard';
import { AgricolaGuard } from '../guard/agricola.guard';
import { AgronodoGuard } from '../guard/agronodo.guard';
import { SpecialistGuard } from '../guard/specialist.guard';

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  declarations: [],
  exports: [RouterModule],
  providers: [AgencyGuard, AgricolaGuard, AgronodoGuard, SpecialistGuard],
})
export class RoutesModule {}
