import { Injectable } from '@angular/core';
import * as introJs from 'intro.js';

@Injectable({
  providedIn: 'root',
})
export class TutorialsService {
  introJS = introJs();
  currentHelp: any = [];

  empty = [
    {
      element: '#step1',
      title: 'Nada para mostrar',
      intro: 'No tenemos datos para mostrar',
      position: 'bottom',
    },
  ];

  home = [
    {
      element: '#step1',
      title: 'Bienvenido',
      intro:
        'Genera un informe de tu agricola, por fecha o filtrando por usuarios.',
      position: 'bottom',
    },
    {
      element: '#step2',
      title: 'Filtrado',
      intro: 'Filtra por lotes y sectores, seleccionando lo que necesites.',
      position: 'bottom',
    },
  ];

  fields = [
    {
      element: '#step1',
      title: 'Nuevo lote',
      intro: 'Agrega nuevo lote a tu agricola.',
      position: 'bottom',
    },
    {
      element: '#step2',
      title: 'Filtrado',
      intro: 'Realiza una busqueda por nombre.',
      position: 'bottom',
    },
    {
      element: '#step3',
      title: 'Edita',
      intro: 'Edita el predio de la lista.',
      position: 'bottom',
    },
    {
      element: '#step4',
      title: 'Visualizar',
      intro: 'Visualiza datos del predio, recorridos y reportes.',
      position: 'bottom',
    },
    {
      element: '#step5',
      title: 'Eliminar',
      intro: 'Elimina permanentemente este elemento.',
      position: 'bottom',
    },
    {
      element: '#step6',
      title: 'Mapa de lotes',
      intro: 'Observa la ubicación de todos tus predios.',
      position: 'bottom',
    },
  ];

  users = [
    {
      element: '#step1',
      title: 'Nuevo usuario',
      intro: 'Agrega un nuevo usuario a la agricola.',
      position: 'bottom',
    },
    {
      element: '#step2',
      title: 'Editar usuario',
      intro: 'Visualiza datos del usuario o editalos',
      position: 'bottom',
    },
    {
      element: '#step3',
      title: 'Eliminar permanentemente',
      intro: 'Elimina permanentemente este usuario.',
      position: 'bottom',
    },
  ];

  tasks = [
    {
      element: '#step1',
      title: 'Tipo de tarea',
      intro: 'Cambia entre los tipos de tareas, pendientes y realizadas.',
      position: 'bottom',
    },
    {
      element: '#step2',
      title: 'Nueva tarea',
      intro: 'Agrega una nueva tarea, y asignasela a supervisores.',
      position: 'bottom',
    },
    {
      element: '#step3',
      title: 'Visualizar tarea',
      intro:
        'Ve las tareas terminadas o pendientes así como los reportes que se hayan levantado en ella.',
      position: 'bottom',
    },
    {
      element: '#step4',
      title: 'Editar tarea',
      intro: 'Edita los datos de la tarea antes de que se realize.',
      position: 'bottom',
    },
    {
      element: '#step5',
      title: 'Eliminar permanentemente',
      intro: 'Elimina permanentemente esta tarea.',
      position: 'bottom',
    },
    {
      element: '#step6',
      title: 'Fecha de cumplimiento',
      intro:
        'Color verde: Para hoy, Color azul: Libre, Color amarillo: Faltan más de 7 días, Color rojo: Haz excedido de los días.',
      position: 'bottom',
    },
    {
      element: '#step7',
      title: 'Filtrado',
      intro: 'Realiza una busqueda por nombre.',
      position: 'bottom',
    },
  ];

  incidencesView = [
    {
      element: '#step1',
      title: 'Primer reporte - Reporte origen',
      intro:
        'Verifica el estado del primer reporte y comparalo conforme fue progresando la incidencia atraves del tiempo.',
      position: 'bottom',
    },
    {
      element: '#step2',
      title: 'Lista de incidencias',
      intro:
        'Selecciona la incidencia de la lista que desees ver, son relacionados a la misma posicion/planta esto para ver como es que fue progresando en el tiempo la incidencia.',
      position: 'bottom',
    },
    {
      element: '#step3',
      intro: 'Con este botón puedes cambiar el reporte de la derecha.',
      position: 'bottom',
    },
    {
      element: '#step4',
      intro:
        'También puedes observar la ubicación de la incidencia en el mapa con este botón.',
      position: 'bottom',
    },
    {
      element: '#step5',
      title: 'Vista del reporte',
      intro:
        'Puedes ver las fotografías tomadas, audios y videos, así como el estado de la planta y la descripción u observación.',
      position: 'bottom',
    },
  ];

  incidences = [
    {
      element: '#step1',
      title: 'Estado de la planta',
      intro:
        'Verifica el estado de tus cultivos, en suelo, planta y fruto así como la cantidad de incidencia encontrada. En caso de ser un problema técnica analiza la descripción de tus monitoreadores.',
      position: 'bottom',
    },
    {
      element: '#step2',
      intro: 'Busqueda por nombre de reporte',
      position: 'bottom',
    },
    {
      element: '#step3',
      intro:
        'Tipo de incidencias, dónde las agrícolas son las que tienen que ver con la planta',
      position: 'bottom',
    },
    {
      element: '#step4',
      intro: 'Filtro de predios de tu agricola',
      position: 'bottom',
    },
    {
      element: '#step5',
      intro: 'Incidencias activas e inactivas (Que no se han solucionado)',
      position: 'bottom',
    },
    {
      element: '#step6',
      intro:
        'De menor a mayor peligro basandose en los problemas que se hayan levantado.',
      position: 'bottom',
    },
    {
      element: '#step7',
      intro:
        'Ve el historial de la incidencia, así como sus antiguos y más recientes reportes.',
      position: 'bottom',
    },
  ];

  informs = [
    {
      element: '#step1',
      intro:
        'Seleciona de que fecha a que fecha deseas ver los informes (Asegurate que cubran alguna de las fecha de ciclos de cultivo).',
      position: 'bottom',
    },
    {
      element: '#step2',
      intro:
        'Seleciona un usuario especifico al que se le hayan echo informes.',
      position: 'bottom',
    },
    {
      element: '#step3',
      intro:
        'Filtra por lotes o sectores para que analices la información de uno en específico o no selecciones ninguno para que veas los que son de toda la agricola.',
      position: 'bottom',
    },
    {
      element: '#step4',
      intro:
        'Visualiza los informes en el mismo navegador o descargalo para imprimir.',
      position: 'bottom',
    },
  ];

  notifications = [
    {
      element: '#step1',
      intro: 'Alterna entre las que no haz visto y las ya visualizadas',
      position: 'bottom',
    },
    {
      element: '#step2',
      intro:
        'Existen 6 tipos de notificaciones, las que son de tarea, por configuraciones, incidencias encontradas, recorridos realizados',
      position: 'bottom',
    },
    {
      element: '#step3',
      intro:
        'El titulo es la descripción específica del tipo de notificación que es',
      position: 'bottom',
    },
    {
      element: '#step4',
      intro:
        'El contenido es una breve descripción al tipo de notificaciones, e incluye datos como, de donde viene y quien la hizo',
      position: 'bottom',
    },
  ];

  personal = [
    {
      element: '#step1',
      intro: 'Visualiza los datos filtrando por lote o sector',
      position: 'bottom',
    },
    {
      element: '#step2',
      intro:
        'Selecciona los tipos de logros(Metas u objetivos a cumplir para mejorar el uso de agronodo) o consejos(Para hacer un uso más eficiente de la herramienta, te damos una serie de consejos)',
      position: 'bottom',
    },
    {
      element: '#step3',
      intro:
        'Visualiza todos, los logros, los pendientes o los ya completados. Estos se muestran por cada ciclo de cultivo.',
      position: 'bottom',
    },
  ];

  resources1 = [
    {
      element: '#step1',
      intro:
        'Selecciona la opción de ver más para visualizar los recursos de cada sección mencionada en este apartado. Hay datos interesantes que pueden servir a tu parcela.',
      position: 'bottom',
    },
  ];

  constructor() {}

  runHelper() {
    this.introJS.start();
  }

  getHelps(type?: String) {
    if (type) {
      switch (type) {
        case 'agricola-home':
          this.currentHelp = this.home;
          break;
        case 'agricola-lotes':
          this.currentHelp = this.fields;
          break;
        case 'agricola-incidencias':
          this.currentHelp = this.incidences;
          break;
        case 'resources-1':
          this.currentHelp = this.resources1;
          break;
        case 'informs':
          this.currentHelp = this.informs;
          break;
        case 'notifications':
          this.currentHelp = this.notifications;
          break;
        case 'personal':
          this.currentHelp = this.personal;
          break;
        case 'tasks':
          this.currentHelp = this.tasks;
          break;
        case 'users':
          this.currentHelp = this.users;
          break;
        default:
          this.currentHelp = this.empty;
          return [];
      }
    }
    return [];
  }
}
