<div class="modal-header">
  <div class="brand-logo" style="font-size: 20px; padding: 8px">
    <img
      class="img-fluid"
      src="assets/img/logo.png"
      alt="App Logo"
      style="height: 54px"
    />
    {{validateText(type) | translate}}
  </div>
</div>
<div class="modal-body mt-2">
  <form
    [formGroup]="informsGroup"
    (submit)="generateInform(informsGroup.value)"
  >
    <div class="row">
      <ng-container
        *ngIf="informsGroup.controls['dateOption'].value !== 'currentCycle'; else cycleTemplate"
      >
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <label class="m-0 my-1">{{'generals.begin_date' | translate}}</label>
          <input
            type="date"
            class="form-control"
            formControlName="start_date"
          />
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <label class="m-0 my-1">{{'generals.end_date' | translate}}</label>
          <input
            type="date"
            class="form-control"
            formControlName="finish_date"
          />
        </div>
      </ng-container>
      <ng-template #cycleTemplate>
        <div class="col-lg-8 col-sm-12 center flex-column">
          <b>Ciclo actual</b>
          <p class="m-0">Se obtendran los datos del ciclo actual</p>
        </div>
      </ng-template>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <label class="m-0 my-1">{{'generals.user' | translate}}</label>
        <select class="form-control" formControlName="user">
          <option value="">{{'generals.select_user' | translate}}</option>
          <option *ngFor="let item of users" [value]="item.user.id">
            {{item.names}}
          </option>
        </select>
      </div>

      <div class="col-12 mt-4 text-left">
        <label>Filtrar por:</label>
        <div
          class="d-flex ml-4 date-radios"
          style="column-gap: 15px; flex-wrap: wrap"
        >
          <label>
            <input type="radio" formControlName="dateOption" value="specific" />
            Fechas especificas
          </label>
          <label>
            <input type="radio" formControlName="dateOption" value="today" />
            Del día de hoy
          </label>
          <label>
            <input
              type="radio"
              formControlName="dateOption"
              value="last7Days"
            />
            De 7 días atrás
          </label>
          <label>
            <input type="radio" formControlName="dateOption" value="thisWeek" />
            De la semana
          </label>
          <label>
            <input
              type="radio"
              formControlName="dateOption"
              value="thisMonth"
            />
            Del mes actual
          </label>
          <label>
            <input
              type="radio"
              formControlName="dateOption"
              value="currentCycle"
            />
            Ciclo actual
          </label>
        </div>
      </div>
      <div class="w-100 center my-3" *ngIf="loadingInform">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </form>
</div>
