import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() type: string;
  @Input() vh: string;

  messageSuccess: boolean = true;

  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{                           //<<<---using ()=> syntax
      this.messageSuccess = false;
    }, 5000);
  }

}
