<div>
  <!-- <ul class="nav nav-tabs" *ngIf="view_mode === 'create_fields'">
    <li class="nav-item c-pointer" (click)="type = 'polygon'">
      <a class="nav-link" [ngClass]="{'active': type === 'polygon'}"
        >Poligono</a
      >
    </li>
    <li class="nav-item c-pointer" (click)="type ='circle'">
      <a class="nav-link" [ngClass]="{'active': type === 'circle'}">Circular</a>
    </li>
  </ul> -->
  <div
    class="d-flex align-items-center flex-column mb-2"
    style="width: 200px"
    *ngIf="type === 'circle'"
  >
    <input
      type="range"
      id="range_radius"
      class="w-100"
      [(ngModel)]="radius"
      name="range_radius"
      min="100"
      max="5000"
      step="10"
    />
    <input type="number" [(ngModel)]="radius" class="form-control" />
  </div>
</div>
<div class="position-relative">
  <button
    class="btn-deshacer pos-button text-center"
    type="button"
    (click)="unDoMarker()"
    *ngIf="coords_polyline.length > 0 && view_mode === 'create_fields'"
  >
    Deshacer<img
      src="assets/img/icons/regresa.svg"
      alt="regresar"
      width="25"
      class="m-2"
    />
  </button>
</div>

<google-map
  [mapId]="map"
  [height]="height"
  [width]="width"
  [center]="center"
  [zoom]="zoom"
  [options]="options_maps"
  (mapClick)="mapClickEvent($event)"
>
  <map-polyline
    *ngIf="polyline"
    [path]="polyline.coordinates"
    [options]="options_polyline"
  >
  </map-polyline>
  <ng-container
    *ngIf="view_mode == 'view_polygon' || view_mode == 'create_report'"
  >
    <ng-container
      *ngIf="typeReportsView == 'individual' || view_mode == 'create_report'"
    >
      <map-marker
        #marker="mapMarker"
        *ngIf="reportsToRender"
        [position]="reportsToRender.position"
        [icon]="iconsValidators(reportsToRender)"
        (mapClick)="openReport(marker, reportsToRender)"
      >
        <map-circle
          [center]="reportsToRender.position"
          [radius]="reportsToRender.radius"
          [options]="reportsToRender.options"
        >
        </map-circle>

        <map-info-window>
          <app-infowindow-sector></app-infowindow-sector>
        </map-info-window>
      </map-marker>
    </ng-container>

    <ng-container *ngIf="typeReportsView == 'pathings'">
      <ng-container *ngIf="reportsToRender && reportsToRender.length > 0">
        <map-marker
          #pathingMarker="mapMarker"
          *ngFor="let report of reportsToRender; trackBy: trackByReport"
          [position]="report.position"
          [icon]="iconsValidators(report)"
          (mapClick)="openReport(pathingMarker, report)"
        >
          <map-circle
            [center]="report.position"
            [radius]="report.radius"
            [options]="report.options"
          >
          </map-circle>

          <map-info-window>
            <app-infowindow-sector></app-infowindow-sector>
          </map-info-window>
        </map-marker>
      </ng-container>

      <ng-container *ngIf="startFinishMarkers">
        <map-marker
          #startMarker="mapMarker"
          [position]="startFinishMarkers.first.position"
          [icon]="startFinishMarkers.first.icon"
        >
        </map-marker>

        <map-marker
          #finishMarker="mapMarker"
          [position]="startFinishMarkers.last.position"
          [icon]="startFinishMarkers.last.icon"
        >
        </map-marker>
      </ng-container>

      <ng-container
        *ngIf="pathingData && optionMapView && optionMapView.alternate"
      >
        <ng-container *ngIf="optionMapView.incidence">
          <map-polyline
            [path]="pathingData.reportsCoordinates"
            [options]="{strokeColor: '#ff0000'}"
          >
          </map-polyline>
        </ng-container>
        <ng-container *ngIf="optionMapView.arrow">
          <map-polyline
            [path]="pathingData.coordinates"
            [options]="{strokeColor: '#4caf50'}"
          >
          </map-polyline>
        </ng-container>
      </ng-container>
    </ng-container>

    <div *ngIf="list_polygon && list_polygon[0] != undefined">
      <div *ngFor="let item of list_polygon">
        <map-polygon
          [paths]="item.coordinates"
          [options]="item.options"
          (polygonClick)="onClickPolygon(item)"
        >
          <map-marker
            *ngIf="item.marker"
            [position]="item.marker.position"
            [label]="item.marker.label"
            [options]="item.marker.options"
            [icon]="item.marker.icon"
            (mapDragend)="dragEndMarker($event)"
          >
          </map-marker>
        </map-polygon>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="view_mode == 'create_fields' && type === 'circle'">
    <map-marker
      [options]="options_marker"
      [position]="circleCenter"
      (mapDragend)="dragEndMarker($event,i)"
    ></map-marker>
    <map-circle
      [center]="circleCenter"
      [radius]="radius"
      [draggable]="true"
      (circleDrag)="circledrag($event)"
    />
  </ng-container>

  <ng-container *ngIf="view_mode == 'create_fields'&& type === 'polygon'">
    <div *ngIf="coords_polyline">
      <div *ngFor="let marker of coords_polyline;let i = index;">
        <map-marker
          [options]="options_marker"
          [position]="{lat:marker.lat,lng:marker.lng}"
          (mapDragend)="dragEndMarker($event,i)"
        ></map-marker>
      </div>
      <map-polyline [path]="coords_polyline"></map-polyline>
    </div>
  </ng-container>
  <ng-container
    *ngIf="view_mode == 'create_objective' || view_mode == 'view_objective'"
  >
    <map-polyline
      *ngIf="objectivesPoly && objectivesPoly?.length > 0 && typeTask != 1"
      [path]="objectivesPoly"
    ></map-polyline>

    <ng-container *ngFor="let objective of objectives; let i = index">
      <map-marker
        #objectiveMarker="mapMarker"
        [position]="objective.position"
        [icon]="objective.report ? iconsValidators(objective.report) : ''"
        (mapClick)="openObjective(objectiveMarker, objective)"
      >
        <map-info-window *ngIf="infoObjective">
          <p>
            {{ "task.objective" | translate }}: {{infoObjective.description ==
            '' ? 'Sin descripción' : infoObjective.description}}
          </p>
          <div class="center p-2" *ngIf="view_mode == 'view_objective'">
            <span
              [class]="
              infoObjective.done
                ? 'badge badge-success'
                : 'badge badge-warning'
            "
            >
              {{ infoObjective.done ? ("task.finished" | translate) :
              ("task.process" | translate) }}</span
            >
          </div>

          <div class="center" *ngIf="view_mode == 'create_objective'">
            <button
              class="btn btn-sm btn-danger"
              (click)="removeObjective(infoObjective.index)"
            >
              <small>Remover</small>
            </button>
          </div>
        </map-info-window>
      </map-marker>
    </ng-container>
  </ng-container>
</google-map>
