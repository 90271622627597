import { UsersService } from './../../Services/agricola/user.service';
import { AgencyService } from './../../Services/agronodo/agency.service';
import { SpecialistService } from './../../Services/agronodo/specialist.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsernameValidator } from 'src/app/validators/UsernameValidator';
import { AdminAgronodo } from 'src/app/Services/agronodo/admin-agronodo.service';
import { AdminAgricola } from 'src/app/Services/agricola/admin-agricola.service';
import { AgricolaAgronodo } from 'src/app/Services/agronodo/agricola-agronodo.service';
import { SupervisorService } from 'src/app/Services/agricola/supervisor.service';
import { ProfileChangeService } from './profile_changes.service';

import Swal from 'sweetalert2';
import { Uris } from 'src/app/Services/public/Uris';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/Services/public/auth.service';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/Services/shared/storage.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  id: any;

  dataUser: any = {};
  role: number = 1;

  user: FormGroup;

  filephoto: any = '';
  picture: any = '';

  selectedService = 'agricolaService';
  modeSubsciption: Subscription;
  constructor(
    public router: Router,
    public modalService: NgbModal,
    public agricolaService: AgricolaAgronodo,
    public supervisorService: SupervisorService,
    public adminAgricolaService: AdminAgricola,
    public adminAgronodoService: AdminAgronodo,
    public specialistService: SpecialistService,
    public agencyService: AgencyService,
    public AuthService: AuthService,
    public profileChangeService: ProfileChangeService,
    public storageService: StorageService,
    public usersService: UsersService
  ) {
    this.user = new FormGroup({
      names: new FormControl(''),
      name: new FormControl('', Validators.required),
      username: new FormControl('', [
        Validators.required,
        UsernameValidator.cannotContainSpace,
      ]),
      email: new FormControl('', [Validators.email, Validators.required]),
      address: new FormControl('', [Validators.required]),
      contact_name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required]),
      instagram: new FormControl(''),
      facebook: new FormControl(''),
      twitter: new FormControl(''),
      linkedin: new FormControl(''),
      profile: new FormControl(''),
    });
  }

  ngOnInit() {
    this.modeSubsciption = this.storageService
      .watchStorage('mode')
      .subscribe(async (mode) => {
        this.FetchData();
      });
  }

  ngAfterContentInit() {
    this.FetchData();
  }

  FetchData() {
    this.AuthService.getProfile().subscribe((resp: any) => {
      this.id = resp.profile.id;
      this.role = resp.user_type;
      this.dataUser = resp;

      const {
        name,
        photo,
        address,
        contact_name,
        phone,
        names,
        facebook,
        profile,
        twitter,
        instagram,
        linkedin,
      } = resp.profile;

      const { username, email } = resp;

      if (photo) {
        this.picture = photo;
      }

      switch (resp.user_type) {
        // Admin
        case 1: {
          this.selectedService = 'adminAgronodoService';
          this.user.patchValue({
            username: username,
            email: email,
          });
          break;
        }
        // Agronodo
        case 2: {
          this.selectedService = 'adminAgronodoService';
          this.user.patchValue({
            username: username,
            email: email,
          });
          break;
        }
        //Admin agronodo
        case 3: {
          this.selectedService = 'adminAgronodoService';
          this.user.patchValue({
            names: names,
            phone: phone,
            username: username,
            email: email,
          });
          break;
        }
        //Agricola
        case 4: {
          this.selectedService = 'agricolaService';
          this.user.patchValue({
            name: name,
            username: username,
            email: email,
            address: address,
            contact_name: contact_name,
            phone: phone,
          });
          break;
        }
        // Admin Agricola
        case 5: {
          this.selectedService = 'adminAgricolaService';
          this.user.patchValue({
            username: username,
            email: email,
            names: names,
            phone: phone,
          });
          break;
        }
        //Admin Supervisor
        case 6: {
          this.selectedService = 'supervisorService';
          this.user.patchValue({
            names: names,
            email: email,
            phone: phone,
            username: username,
          });
          break;
        }
        //Specialist
        case 8: {
          this.selectedService = 'specialistService';
          this.user.patchValue({
            names: names,
            email: email,
            phone: phone,
            username: username,
            facebook: facebook,
            profile: profile,
            twitter: twitter,
            instagram: instagram,
            linkedin: linkedin,
          });
          break;
        }
        //Agency
        case 9: {
          this.selectedService = 'agencyService';
          this.user.patchValue({
            name: name,
            email: email,
            phone: phone,
            username: username,
            address: address,
            contact_name: contact_name,
          });
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  submitUser(value) {
    Swal.fire({
      text: 'Guardar información',
      allowOutsideClick: false,
      width: '270px',
    });

    Swal.showLoading();

    let obj = {};

    if (this.role == 4 || this.role == 9) {
      obj = {
        name: value.name,
        address: value.address,
        contact_name: value.contact_name,
        phone: value.phone,
      };
    }
    if (this.role == 3 || this.role == 5 || this.role == 6) {
      obj = {
        names: value.names,
        phone: value.phone,
      };
    }

    if (this.role == 8) {
      obj = {
        names: value.names,
        phone: value.phone,
        profile: value.profile,
        instagram: value.instagram,
        facebook: value.facebook,
        twitter: value.twitter,
        linkedin: value.linkedin,
      };
    }

    if (this.filephoto) {
      obj['photo'] = this.filephoto;
    }

    this[this.selectedService].edit(obj, this.id).subscribe((edited) => {
      if (edited) {
        this.usersService.getProfile().subscribe((res) => {
          localStorage.setItem('USER', JSON.stringify(res));
          this.profileChangeService.notifyLocalStorageChange('USER');
        });

        Swal.fire({
          text: 'Se Actualizó correctamente' + value.name,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          width: '250px',
        });
        this.closeModal();
      }
    });
  }

  getbase64(base64: string) {
    this.filephoto = base64;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
