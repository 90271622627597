<div
  class="item user-block my-2 ml-2"
  *ngIf="userBlockIsVisible()"
  (click)="openVerticallyCentered()"
>
  <!-- User picture-->
  <div class="d-flex justify-content-center align-items-center">
    <div class="img-userBlock">
      <img
        class="img-fluid"
        [class]="
          !settings.getLayoutSetting('isCollapsed') ? 'profile' : 'profile-2'
        "
        [src]="photo"
        alt="Avatar"
        (error)="photo = 'assets/img/logo.png'"
      />
    </div>
  </div>
  <!-- Name and Job-->
  <div
    class="user-block-info text-white"
    *ngIf="user && !settings.getLayoutSetting('isCollapsed')"
  >
    <div class="center mb-2" *ngIf="user.user_type == 5 || user.user_type == 6">
      <img
        [src]="user.profile.agricola_photo"
        style="height: 20px; width: 20px"
        class="avatar mr-1"
      />
      <small>{{ user.profile.agricola_name }}</small>
    </div>
    <span class="user-block-role">{{ user ? user.user_type_name : "" }}</span>
    <span class="user-block-name">
      {{ user.profile.names ? user.profile.names : "" }}</span
    >
    <span class="user-block-name">
      {{ user.profile.name ? user.profile.name : "" }}</span
    >
  </div>
</div>

<ng-template #longContent>
  <div class="modal-header">
    <div class="brand-logo" style="font-size: 20px; padding: 8px">
      <img
        class="img-fluid"
        [src]="'assets/img/logo.png'"
        alt="App Logo"
        style="height: 54px"
        (error)="('assets/img/logo.png')"
      />
      {{ "profile.title" | translate }}
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="globalFunctions.Modal(longContent, 'hide', {})"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2">
    <div class="container">
      <app-profile></app-profile>
    </div>
  </div>
</ng-template>
