<div class="modal-content" style="width: 116%;">
    <div class="modal-header p-0">
        <div class="brand-logo" style="font-size: 20px; padding: 8px;">
            <img class="img-fluid" src="assets/img/logo.png" alt="App Logo" style="height: 54px;" />
            {{cicle.progress === '' || type ? 'Agregar ciclo' : 'Extender el ciclo'}}
        </div>
        <div>
            <button type="button" class="close" aria-label="Close" (click)="hideModal(cicle)"
                style="font-size: 34px; padding: 23px;">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

    </div>
    <div class="modal-body">
        <div class="container">
            <form [formGroup]="cicloForm" (submit)="ciclo(sublote.id,cicle, cicloForm.value);">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label>Fecha de inicio</label>
                        <input type="date" class="form-control" formControlName="start_date" />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card-modal">
                        <label>Fecha final</label>
                        <input type="date" class="form-control" formControlName="finish_date" />
                    </div>
                    <div class="d-flex justify-content-end align-items-end" style="width: 100%;">
                        <div class="p-3">
                            <button *ngIf="cicloForm.valid" type="submit" class="btn btn-info">
                                Guardar ciclo <em class="far fa-calendar-plus p-1"></em>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>