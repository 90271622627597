import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../Services/public/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private auth: AuthService, public router: Router) {}

  canActivate(): boolean {
    if (this.auth.leerToken()) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
