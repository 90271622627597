import { SharedMapService } from './../../routes/agricola/fields/sectors/sector/map/shared-map.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { GlobalFunction } from '../../Services/public/globalFunction';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { fin, inicio, transparent } from 'src/app/Services/public/icons';
import { mode, polygon, polyline, coordinates } from './map.interface';

@Component({
  selector: 'app-map',
  templateUrl: './map.html',
  styleUrls: ['./map.scss'],
})
export class MapComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  @Input() height: any = 300;
  @Input() width: any = 300;
  /* la idea es que esta variable cambie toda configuracion...  */
  @Input() view_mode: mode = 'view_polygon';

  @Input() zoom: number = 20;

  /* Vista de poligonos con nombres view_polygon */
  @Input() list_polygon: Array<polygon>;
  @Input() polyline: polyline;
  /* Crear poligonos y coordenadas  create_fields*/
  @Input() coords_polyline: google.maps.LatLngLiteral[] = [];

  @Input() ArrayMaker: Array<any>;
  @Input() iconmarker: any;
  @Input() PolylineCoordinates;
  @Input() PolylineCoordinatesSubfield;
  @Input() PolylineCoordinatesSubfieldColor;
  @Input() PolylineCoordinatesAllSubfields;
  @Input() PolylineColor = '#4caf50';

  //Create report variables
  @Input() area: number = 0;

  //Task variables
  @Output() objectiveRemoved = new EventEmitter<number>();
  @Input() typeTask: number = 1;
  @Input() currentObjective: any;
  @Input() task: any;
  objectives: any = [];
  objectivesPoly: any = [];
  infoObjective: any;

  //Reports visualizer
  @Input() displayData: any;
  @Input() typeReportsView: string = 'pathings';
  @Input() optionMapView: string;
  reportsToRender: any;
  startFinishMarkers: any;

  @Output('mapClick') mapClick = new EventEmitter();
  @Output('dragEndMarker') dragEnd = new EventEmitter();
  @Output('unDoMarker') unDoMarkerF = new EventEmitter();
  @Output('polygonClick') polygonClick = new EventEmitter();

  type = 'polygon';
  circle: any = {};

  inicio = inicio;
  fin = fin;

  options_maps: google.maps.MapOptions = {
    zoomControl: true,
    streetViewControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
    maxZoom: 40,
    minZoom: 10,
  };

  options_polygon: google.maps.PolygonOptions = {
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillOpacity: 0.35,
    strokeColor: '#4caf50',
  };

  options_polyline: google.maps.PolylineOptions = {
    strokeOpacity: 0.8,
    strokeWeight: 4,
    strokeColor: '#4caf50',
  };

  options_polyIncidenceView: google.maps.PolylineOptions = {
    strokeColor: 'red',
    strokeOpacity: 1.0,
    strokeWeight: 2,
    icons: [
      {
        icon: {
          path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 4,
          strokeColor: 'red',
        },
        offset: '100%',
        repeat: '50px',
      },
    ],
  };

  circleCenter: google.maps.LatLngLiteral = { lat: 10, lng: 15 };
  radius = 10;

  @Input() options_marker: google.maps.ReadonlyMarkerOptions = {
    draggable: true,
    optimized: true,
  };

  center: google.maps.LatLngLiteral;

  constructor(
    public sharedMapService: SharedMapService,
    public globalFunction: GlobalFunction,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.view_mode === 'view_polygon') {
      this.list_polygon && this.validateListPolygon();
      this.validateReports();
    }

    this.validateCenterMap();

    if (this.view_mode === 'create_objective') {
      if (changes['currentObjective']) this.validateObjectives();
      if (changes['task']) this.validateTask();
    }

    if (this.view_mode === 'view_objective') {
      if (changes['task']) this.validateTask();
    }

    if (this.view_mode === 'create_report') {
      if (changes['area']) this.validateReport();
    }

    this.cdRef.detectChanges();
  }

  ngOnInit() {
    if (this.view_mode === 'view_polygon' && this.list_polygon) {
      this.validateListPolygon();
    }

    if (this.polyline) {
      this.validatePolyline();
      this.validateReports();
    }
  }

  mapClickEvent(event: google.maps.MapMouseEvent) {
    /*     this.type === 'circle' ? (this.circle = event) : this.mapClick.emit(event); */
    let { lat, lng } = event.latLng;
    console.log(this.view_mode);

    if (this.view_mode === 'create_fields') {
      this.coords_polyline.push({ lat: lat(), lng: lng() });
      this.coords_polyline = [
        ...this.globalFunction.ValidatePolygon(this.coords_polyline),
      ];
      this.mapClick.emit(this.coords_polyline);
    } else if (
      this.view_mode === 'create_objective' ||
      this.view_mode === 'create_report'
    ) {
      this.reportsToRender = {
        position: { lat: lat(), lng: lng() },
      };
      this.mapClick.emit({ lat: lat(), lng: lng() });
    }
  }

  unDoMarker(event) {
    this.coords_polyline = [
      ...this.globalFunction.validateBack(this.coords_polyline),
    ];
  }

  dragEndMarker(event, index) {
    let { lat, lng } = event.latLng;
    if (this.type === 'circle') {
      this.circleCenter = { lat: lat(), lng: lng() };
    }
    if (this.type === 'polygon') {
      /* cuando demos click en el ultimo o en el primero debemos de editar ambos */
      if (index === this.coords_polyline.length - 1 || event === 0) {
        this.coords_polyline[0] = { lat: lat(), lng: lng() };
        this.coords_polyline[this.coords_polyline.length - 1] = {
          lat: lat(),
          lng: lng(),
        };
      } else {
        if (index >= 0) {
          this.coords_polyline[index] = { lat: lat(), lng: lng() };
        }
        if (index === this.coords_polyline.length - 1) {
          this.coords_polyline.splice(index, 1);
          this.coords_polyline.push(this.coords_polyline[0]);
        }
      }
      console.log(index, this.coords_polyline.length - 1);
      this.coords_polyline = [...this.coords_polyline];
    }
    /* this.dragEnd.emit({ event, array: this.ArrayMaker }); */
  }

  validateCoordinates(value) {
    return this.globalFunction.validateCoordinates(value);
  }

  markerDragEnd(event, i) {
    this.ArrayMaker[i] = event.coords;
    this.ArrayMaker.shift();
    this.ArrayMaker = this.globalFunction.validateCoordinates(this.ArrayMaker);
    this.dragEnd.emit({ event, array: this.ArrayMaker });
  }

  deleteMarker(event, i) {
    this.ArrayMaker.splice(i, 1);
    this.ArrayMaker.shift();
    this.ArrayMaker = this.globalFunction.validateCoordinates(this.ArrayMaker);
    this.dragEnd.emit({ event, array: this.ArrayMaker });
  }

  openReport(marker: MapMarker, report: any) {
    this.sharedMapService.updateReport(report);
    this.infoWindow.open(marker);
  }

  openObjective(marker: MapMarker, objective: any) {
    this.infoObjective = objective;

    if (this.view_mode === 'view_objective') {
      this.mapClick.emit(this.infoObjective);
    }

    this.infoWindow.open(marker);
  }

  /* Centrar en el mapa */
  validateCenterMap() {
    const center_polygon = (coordinates) => {
      const { lat, lng } = this.globalFunction.centerPolygon(coordinates);
      this.center = { lat, lng };
      this.circleCenter = { lat, lng };
    };

    let coordinates = null;

    if (this.polyline) {
      coordinates = this.polyline.coordinates;
    } else if (this.list_polygon && this.list_polygon[0] !== undefined) {
      coordinates = this.list_polygon[0].coordinates;
    } else if (this.coords_polyline && this.coords_polyline.length > 0) {
      coordinates = this.coords_polyline;
    } else {
      const location = this.globalFunction.getUserLocation();
      coordinates = location
        ? location
        : [{ lat: 25.793611111111, lng: -108.99694444444 }];
    }

    if (coordinates) {
      center_polygon(coordinates);
    }

    this.zoom = 15;
  }

  /* Funcion para crear el marcador de los poligonos  */
  validateListPolygon() {
    let options = this.options_polygon;

    if (this.list_polygon) {
      this.list_polygon = this.list_polygon.map((item) => {
        if (item === undefined) {
          return undefined;
        }

        const { lat, lng } = this.globalFunction.centerPolygon(
          item.coordinates
        );
        let marker: google.maps.ReadonlyMarkerOptions | null = null;

        if (item.name) {
          marker = {
            icon: transparent,
            position: { lat, lng },
            label: {
              color: 'white',
              text: item.name,
              fontWeight: '800',
            },
          };
        }

        if (item.color) {
          options = {
            ...options,
            strokeColor: item.color,
            strokeWeight: 3,
          };
        }

        // Asegúrate de retornar un objeto válido
        return {
          ...item,
          options,
          marker,
        };
      });
    }
  }

  validatePolyline() {
    if (this.polyline.color) {
      this.options_polyline.strokeColor = this.polyline.color;
    }
  }

  pathingData: any = {};
  validateReports() {
    if (!this.displayData && this.displayData?.id != -1) return;

    if (this.typeReportsView === 'individual') {
      this.reportsToRender = this.createReportObject(this.displayData);
    } else if (this.typeReportsView === 'pathings') {
      this.displayPathing(this.displayData);
    }
  }

  validateReport() {
    this.reportsToRender = {
      ...this.reportsToRender,
      radius: this.area,
      options: {
        fillColor: '#0091f4',
        strokeColor: '#0091f4',
      },
    };
  }

  validateTask() {
    if (!this.task) return;
    console.log(this.task);

    this.objectives = this.task.objectives.map((obj) => ({
      position: {
        lat: obj.lat,
        lng: obj.lng,
      },
      description: obj.description,
      index: obj.index,
      done: obj.done ? obj.done : false,
      report: obj.report ? obj.report : undefined,
    }));

    this.createObjectivesPoly();
    this.typeTask = this.task.type;
  }

  validateObjectives() {
    if (!this.currentObjective) return;

    let currentMarker: any = {
      position: {
        lat: this.currentObjective.lat,
        lng: this.currentObjective.lng,
      },
      index: this.currentObjective.index,
      description: this.currentObjective.description,
    };

    this.objectives.push(currentMarker);
    this.createObjectivesPoly();
  }

  createObjectivesPoly() {
    // Actualizar objectivesPoly basado en las coordenadas de objectives
    this.objectivesPoly = this.objectives.map((obj) => ({
      lat: obj.position.lat,
      lng: obj.position.lng,
      index: obj.index,
    }));
  }

  removeObjective(id: number) {
    this.objectives = this.objectives.filter((obj) => obj.index !== id);
    this.objectivesPoly = this.objectivesPoly.filter((obj) => obj.index !== id);
    this.objectiveRemoved.emit(id);
  }

  displayPathing(path) {
    const reportsCoordinates = this.setPoints(path.coordinates, path.reports);

    this.pathingData = {
      coordinates: path.coordinates,
      reports: path.reports,
      reportsCoordinates,
    };

    this.reportsToRender = this.pathingData.reports.map((report) =>
      this.createReportObject(report)
    );

    this.setStartFinishMarkers(this.pathingData.coordinates);
  }

  setStartFinishMarkers(coordinates) {
    const { first, last } = this.getFirstAndLast(coordinates);

    this.startFinishMarkers = {
      first: first ? { position: first, icon: inicio } : undefined,
      last: last ? { position: last, icon: fin } : undefined,
    };
  }

  setPoints(pathingCoordinates, reports) {
    return [
      pathingCoordinates[0],
      ...reports.map((report) => ({ lat: report.lat, lng: report.lng })),
      pathingCoordinates[pathingCoordinates.length - 1],
    ];
  }

  createReportObject(report) {
    return {
      id: report.id,
      position: { lat: report.lat, lng: report.lng },
      radius: report.area,
      title: report.title,
      type: report.type,
      incidences: report.incidences,
      pathing: report.pathing,
      images: report.images,
      datetime: report.datetime,
      options: {
        fillColor: this.validateColorReport(report),
        strokeColor: this.validateColorReport(report),
      },
    };
  }

  getFirstAndLast<T>(array): { first: T | undefined; last: T | undefined } {
    if (array.length === 0) {
      return { first: undefined, last: undefined };
    }

    return { first: array[0], last: array[array.length - 1] };
  }

  onClickPolygon(polygon) {
    console.log(polygon);
    this.polygonClick.emit(polygon);
  }

  trackByReport(index: number, report: any): string {
    return report.position.lat + '-' + report.position.lng;
  }

  iconsValidators(report) {
    return this.globalFunction.iconsValidatorsRisk(report);
  }

  validateColor(color: any) {
    return this.globalFunction.validateColor(color);
  }

  validateColorReport(report) {
    return this.globalFunction.ColorValidatorsCircleReport(report);
  }
  circledrag(event) {
    console.log(event);
  }
}
