import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Uris } from '../public/Uris';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { GlobalFunction } from '../public/globalFunction';

@Injectable({
  providedIn: 'root',
})
export class FieldsAgricolaService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}

  register(admin: any) {
    return this.http
      .post(`${Uris.API_FIELDS}`, admin, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  listFields() {
    return this.http
      .get(`${Uris.API_FIELDS}`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getField(value) {
    return this.http
      .get(`${Uris.API_FIELDS}${value}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  deleteField(value: any) {
    return this.http
      .delete(`${Uris.API_FIELDS}${value}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  editField(value: any, id: any) {
    return this.http
      .put(`${Uris.API_FIELDS}${id}/`, value, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  deleteSector(value: any) {
    return this.http
      .delete(`${Uris.API_SECTORS}${value}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  sectorRegister(sector: any) {
    return this.http
      .post(`${Uris.API_SECTORS}`, sector, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  editSector(value: any, id: any) {
    return this.http
      .patch(`${Uris.API_SECTORS}${id}/`, value, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getSectors(query: any) {
    return this.http
      .get(`${Uris.API_SECTORS}${query ? query : ""}`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getSectorID(id: any) {
    return this.http
      .get(`${Uris.API_SECTORS}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  cycleRegister(value: any) {
    return this.http
      .post(
        `${Uris.API_CYCLES}`,
        value,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  editCycle(id, value) {
    return this.http
      .put(
        `${Uris.API_CYCLES}${id}/`,
        value,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getListPathingsID(id) {
    return this.http
      .get(`${Uris.API_PATHINGS}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getListPathings(query) {
    return this.http
      .get(`${Uris.API_PATHINGS}${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getCrops() {
    return this.http
      .get(`${Uris.CROPS}`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  registerCrops(admin) {
    return this.http
      .post(`${Uris.CROPS}`, admin, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
  getSeasons(query) {
    return this.http
      .get(`${Uris.API_SEASONS}${query}`, this.GlobalFunction.headers())
      .pipe(
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        }),
        map((resp: any) => {
          return resp;
        })
      );
  }
  createSeason(obj: any) {
    return this.http
      .post(`${Uris.API_SEASONS}`, obj, this.GlobalFunction.headers())
      .pipe(
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        }),
        map((resp: any) => {
          return resp;
        })
      );
  }
  editSeason(obj: any, id: any) {
    return this.http
      .patch(`${Uris.API_SEASONS}${id}/`, obj, this.GlobalFunction.headers())
      .pipe(
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        }),
        map((resp: any) => {
          return resp;
        })
      );
  }
  deleteSeason(id: any) {
    return this.http
      .delete(`${Uris.API_SEASONS}${id}/`, this.GlobalFunction.headers())
      .pipe(
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        }),
        map((resp: any) => {
          return resp;
        })
      );
  }

  Example(data) {
    return this.http
      .post(
        `http://api.agromonitoring.com/agro/1.0/polygons?appid=5161799f7a020f4eccfb1df314baec45
    `,
        data,
        {
          headers: {
            'Accept-language': 'en-es',
          },
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getImage(data) {
    return this.http
      .get(
        `http://api.agromonitoring.com/agro/1.0/image/search?start=1633050559&end=1635208206&polyid=${data}&appid=5161799f7a020f4eccfb1df314baec45
    `,
        {
          headers: {
            'Accept-language': 'en-es',
          },
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
}
