import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Uris } from '../public/Uris';
import { WebsocketService } from './socket.service';
import { map } from 'rxjs/operators';
import { GlobalFunction } from '../public/globalFunction';
import { webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public notification: any;
  Conectionsocket = webSocket(
    `${Uris.SocketUrl}?e98cbfcab20b237547294fa1396c8fe2ad9ebc44b485dcbc24fd738bc703ba08`
  );
  constructor(
    wsService: WebsocketService,
    public http: HttpClient,
    public GlobalFunction: GlobalFunction
  ) {
    /*    const CHAT_URL = `${Uris.SOCKET}${this.leerToken()}/`;
    this.notification = wsService.connect(CHAT_URL).map((response) => {
      let data = JSON.parse(response.data);
      return data;
    }); */
  }

  getNotifications(query) {
    return this.http
      .get(`${Uris.NOTIFICATIONS}${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  NotificationsMarkAsRead(body) {
    return this.http
      .post(
        `${Uris.NOTIFICATIONS}mark_as_read/`,
        body,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  generateToken() {
    return this.http
      .get(`${Uris.SocketUrl}v2/sockets/ticket/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  socket = () => {
    this.Conectionsocket.subscribe((msg: any) => {
      return msg;
    });
  };

  leerToken() {
    return localStorage.getItem('token');
  }
}
