import { Component, OnInit, Input } from '@angular/core';
import { SharedMapService } from 'src/app/routes/agricola/fields/sectors/sector/map/shared-map.service';

@Component({
  selector: 'app-infowindow-sector',
  templateUrl: './infowindow.html',
  styleUrls: ['./infowindow.scss'],
})
export class InfoWindowComponent implements OnInit {
  item: any;

  constructor(public sharedMapService: SharedMapService) {}

  ngOnInit(): void {
    this.sharedMapService.report$.subscribe((res) => {
      res ? (this.item = res) : (this.item = null);
    });
  }

  isNumber(value) {
    return typeof value === 'number';
  }
}
