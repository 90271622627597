const scaledSize = { height: 40, width: 30, equals: null };
export const inicio: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/Inicio.png',
  scaledSize,
};
export const fin: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/final.png',
  scaledSize,
};
export const sano: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/saludable1.png',
  scaledSize,
};
export const Saludablereporte: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/salusablereporte.png',
  scaledSize,
};
export const reportNormal: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/reporte1.png',
  scaledSize,
};
export const alerta: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/alerta1.png',
  scaledSize,
};
export const peligro: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/peligro1.png',
  scaledSize,
};
export const TaskPendiente: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/tareapendiente.png',
  scaledSize,
};
export const TaskIncidencePendiente: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/tareapendienteincidencia.png',
  scaledSize,
};
export const Arrow: google.maps.Icon = {
  url: 'http://earth.google.com/images/kml-icons/track-directional/track-4.png',
  scaledSize,
};
export const transparent: google.maps.Icon = {
  url: '../../../assets/img/Marcadores/saludable1.png',
  scaledSize: { height: 0, width: 0, equals: null },
};
