import { Routes } from '@angular/router';
import { AuthGuard } from '../guard/home.guard';
import { LayoutComponent } from '../layout/layout.component';

// Guards
import { AgencyGuard } from '../guard/agency.guard';
import { AgricolaGuard } from '../guard/agricola.guard';
import { AgronodoGuard } from '../guard/agronodo.guard';
import { SpecialistGuard } from '../guard/specialist.guard';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'agricola',
        loadChildren: () =>
          import('./agricola/agricola.module').then((m) => m.AgricolaModule),
        canActivate: [AuthGuard, /*AgricolaGuard */],
      },
      {
        path: 'agronodo',
        loadChildren: () =>
          import('./agronodo/agronodo.module').then((m) => m.AgronodoModule),
        canActivate: [AuthGuard /* AgronodoGuard */],
      },
      {
        path: 'agency',
        loadChildren: () =>
          import('./agency/agency.module').then((m) => m.AgencyModule),
        canActivate: [AuthGuard /* AgencyGuard */],
      },
      {
        path: 'specialist',
        loadChildren: () =>
          import('./specialist/specialist.module').then(
            (m) => m.SpecialistModule
          ),
        canActivate: [AuthGuard /* SpecialistGuard */],
      },
      {
        path: 'shared',
        loadChildren: () =>
          import('./shared/shared.module').then((m) => m.SharedModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
