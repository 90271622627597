import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileChangeService {
  private localStorageChangesSubject: Subject<string> = new Subject<string>();

  localStorageChanges$: Observable<string> = this.localStorageChangesSubject.asObservable();

  notifyLocalStorageChange(key: string) {
    this.localStorageChangesSubject.next(key);
  }
}