import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SettingsService } from './settings/settings.service';
import { ThemesService } from './themes/themes.service';
import { MenuService } from './menu/menu.service';

@NgModule({
  imports: [],
  providers: [SettingsService, ThemesService, MenuService],
  declarations: [],
  exports: [],
})
export class CoreModule {}
