<div class="row">
  <!-- COLUMNA IZQUIERDA -->
  <div class="col-lg-4 pb-3">
    <!-- FOTO DEL USUARIO -->
    <div class="row">
      <div class="col-12">
        <div>
          <app-image-cropper
            [url]="picture ? picture : ''"
            (setbase64)="getbase64($event)"
          >
          </app-image-cropper>
        </div>
        <ng-container *ngIf="role === 5 || role === 6">
          <div class="center" *ngIf="dataUser.profile">
            <img
              [src]="dataUser.profile.agricola_photo"
              class="avatar"
              style="height: 40px; width: 40px"
              alt=""
              srcset=""
            />
            <h5 class="ml-2 mb-0">{{ dataUser.profile.agricola_name }}</h5>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- PERMISOS DEL USUARIO -->
    <div class="col-12 p-0 mt-4">
      <h4 class="text-left" *ngIf="role === 4 || role === 5 || role === 6">
        {{ "profile.permissions" | translate }}
      </h4>
      <div class="row mt-3" *ngIf="role === 5">
        <div class="col-10 text-left">
          <label>{{ "profile.perm_1" | translate }}</label>
        </div>
        <div class="col-2 text-right">
          <b *ngIf="role === 5">{{
            dataUser.profile.prms_supervisor_bool
              ? ("generals.yes" | translate)
              : ("generals.no" | translate)
          }}</b>
        </div>
      </div>
      <div class="row mt-1" *ngIf="role === 4 || role === 5 || role === 6">
        <div class="col-10 text-left">
          <label>{{ "profile.perm_2" | translate }}</label>
        </div>
        <div class="col-2 text-right">
          <b *ngIf="role === 4">{{ dataUser.profile.prms_fields }}</b>
          <b *ngIf="role === 5 || role === 6">{{
            dataUser.profile.prms_fields_bool
              ? ("generals.yes" | translate)
              : ("generals.no" | translate)
          }}</b>
        </div>
      </div>
      <div class="row mt-1" *ngIf="role === 4">
        <div class="col-10 text-left">
          <label>{{ "profile.perm_8" | translate }}</label>
        </div>
        <div class="col-2 text-right">
          <b *ngIf="role === 4">{{ dataUser.profile.prms_users_extra }}</b>
        </div>
      </div>
      <div class="row mt-1" *ngIf="role === 5">
        <div class="col-10 text-left">
          <label>{{ "profile.perm_3" | translate }}</label>
        </div>
        <div class="col-2 text-right">
          <b *ngIf="role === 5">{{
            dataUser.profile.prms_admin_bool
              ? ("generals.yes" | translate)
              : ("generals.no" | translate)
          }}</b>
        </div>
      </div>
      <div class="row mt-1" *ngIf="role === 6">
        <div class="col-10 text-left">
          <label>{{ "profile.perm_5" | translate }}</label>
        </div>
        <div class="col-2 text-right">
          <b>{{
            dataUser.profile.prms_reports_bool
              ? ("generals.yes" | translate)
              : ("generals.no" | translate)
          }}</b>
        </div>
      </div>
      <div class="row mt-1" *ngIf="role === 6">
        <div class="col-10 text-left">
          <label>{{ "profile.perm_6" | translate }}</label>
        </div>
        <div class="col-2 text-right">
          <b>{{
            dataUser.profile.prms_monitors_bool
              ? ("generals.yes" | translate)
              : ("generals.no" | translate)
          }}</b>
        </div>
      </div>
      <div class="row mt-1" *ngIf="role === 6 || role == 4">
        <div class="col-10 text-left">
          <label>{{ "profile.perm_7" | translate }}</label>
        </div>
        <div class="col-2 text-right">
          <b>{{
            dataUser.profile.prms_tasks_bool
              ? ("generals.yes" | translate)
              : ("generals.no" | translate)
          }}</b>
        </div>
      </div>

      <!-- PREFERENCIAS DE SEGUIMIENTO DEL USUARIO -->
      <div class="row mt-3 text-center" *ngIf="role >= 4 && role <= 6">
        <hr />
        <div class="col-12">
          <b>{{ "profile.preference" | translate }}</b>
        </div>
        <div class="col-12">
          <small *ngIf="dataUser.profile.incidence_tracking_preference === 0">
            {{ "profile.preference_1" | translate }}.
          </small>
          <small *ngIf="dataUser.profile.incidence_tracking_preference === 1">
            {{ "profile.preference_2" | translate }}.
          </small>
          <small *ngIf="dataUser.profile.incidence_tracking_preference === 2">
            {{ "profile.preference_3" | translate }}.
          </small>
        </div>
      </div>
    </div>
  </div>

  <!-- FORMULARIO AGRICOLA -->
  <div class="col-lg-8 py-3 d-flex flex-column custom-padding">
    <form [formGroup]="user" (submit)="submitUser(user.value)">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-0" *ngIf="role != 2">
            {{ "profile.personal" | translate }}
            <b
              *ngIf="
                role == 2 || role == 3 || role == 5 || role == 6 || role == 8
              "
              >{{ dataUser.profile.names }}</b
            ><b *ngIf="role == 4 || role == 9">{{ dataUser.profile.name }}</b>
          </h4>
          <small
            >{{ "profile.user_type" | translate }}:
            <b>{{ dataUser.user_type_name }}</b></small
          >
        </div>
      </div>
      <div class="row py-4 my-auto">
        <div class="col-12">
          <div class="form-group row">
            <div class="col-12">
              <label>{{ "table.user" | translate }}</label>
              <input
                class="form-control"
                formControlName="username"
                type="text"
                disabled
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <label>{{ "table.email" | translate }}</label>
              <input
                class="form-control"
                formControlName="email"
                type="email"
                placeholder="example@example.com"
                disabled
              />
            </div>
          </div>
          <div class="form-group row" *ngIf="role >= 3">
            <div class="col-12">
              <label *ngIf="role == 4">{{
                "table.agricola" | translate
              }}</label>
              <label
                *ngIf="
                  role === 3 ||
                  role === 5 ||
                  role === 6 ||
                  role === 8 ||
                  role === 9
                "
                >{{ "table.name" | translate }}</label
              >
              <input
                *ngIf="role == 4 || role === 9"
                class="form-control"
                formControlName="name"
                type="text"
              />
              <input
                *ngIf="role === 3 || role === 5 || role === 6 || role === 8"
                class="form-control"
                formControlName="names"
                type="text"
              />
              <span
                class="text-danger"
                *ngIf="
                  user.controls['name'].errors &&
                  user.controls['name'].errors['required'] &&
                  role == 4
                "
                >{{ "warnings.invalid_field" | translate }}</span
              >
            </div>
          </div>
          <div class="form-group row" *ngIf="role === 4 || role === 9">
            <div class="col-12">
              <label>{{ "table.c_name" | translate }}</label>
              <input
                class="form-control"
                formControlName="contact_name"
                type="text"
                placeholder="Nombre de contacto"
              />
              <span
                class="text-danger"
                *ngIf="
                  user.controls['name'].errors &&
                  user.controls['contact_name'].errors['required']
                "
                >{{ "warnings.invalid_field" | translate }}</span
              >
            </div>
          </div>

          <div
            class="form-group row"
            *ngIf="
              role === 4 ||
              role === 3 ||
              role === 5 ||
              role == 6 ||
              role == 8 ||
              role == 9
            "
          >
            <div class="col-12">
              <label>{{ "table.phone" | translate }}</label>
              <input
                class="form-control"
                type="number"
                formControlName="phone"
              />
              <span class="text-danger" *ngIf="user.controls['phone'].errors">{{
                "warnings.invalid_field" | translate
              }}</span>
            </div>
          </div>
          <div class="form-group row" *ngIf="role === 4 || role == 9">
            <div class="col-12">
              <label>{{ "table.direction" | translate }}</label>
              <input
                class="form-control"
                formControlName="address"
                type="text"
              />
              <span
                class="text-danger"
                *ngIf="
                  user.controls['name'].errors &&
                  user.controls['address'].errors['required']
                "
                >{{ "warnings.invalid_field" | translate }}</span
              >
            </div>
          </div>
          <div class="form-group row" *ngIf="role === 8">
            <div class="col-12">
              <label>{{ "table.profile" | translate }}</label>
              <input
                class="form-control"
                formControlName="profile"
                type="text"
              />
            </div>
          </div>
          <div class="form-group row" *ngIf="role === 8">
            <div class="col-12 mb-2">
              <h5>Redes sociales</h5>
            </div>
            <div class="col-6">
              <label>Facebook</label>
              <input
                class="form-control"
                formControlName="facebook"
                type="text"
              />
            </div>
            <div class="col-6">
              <label>Instagram</label>
              <input
                class="form-control"
                formControlName="instagram"
                type="text"
              />
            </div>
            <div class="col-6">
              <label>Twitter</label>
              <input
                class="form-control"
                formControlName="twitter"
                type="text"
              />
            </div>
            <div class="col-6">
              <label>Linkedin</label>
              <input
                class="form-control"
                formControlName="linkedin"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <a
            class="nav-link"
            [routerLink]="['/change_password']"
            (click)="closeModal()"
            style="cursor: pointer"
          >
            {{ "generals.password" | translate }}
            <i class="fa fa-lock" aria-hidden="true"></i>
          </a>
          <button class="btn btn-primary" *ngIf="role != 2" type="submit">
            {{ "generals.save" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
