import { Injectable } from '@angular/core';
/* 
const themeA = require('../../shared/styles/themes/theme-a.scss'); */

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  styleTag: any;
  defaultTheme: string = 'A';

  constructor() {
    this.createStyle();
    this.setTheme(this.defaultTheme);
  }

  private createStyle() {
    const head = document.head || document.getElementsByTagName('head')[0];
    this.styleTag = document.createElement('style');
    this.styleTag.type = 'text/css';
    this.styleTag.id = 'appthemes';
    head.appendChild(this.styleTag);
  }

  setTheme(name: string) {
    switch (name) {
      case 'A':
   /*      this.injectStylesheet(themeA); */
        break;
    }
  }

  injectStylesheet(css) {
    this.styleTag.innerHTML = css;
  }

  getDefaultTheme() {
    return this.defaultTheme;
  }
}
