import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

import { AppComponent } from './app.component';
import { RoutesModule } from './routes/routes.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(es);

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternationalizationModule } from './internationalization/internationalization.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

/* import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core'; */

import { NgwWowModule } from 'ngx-wow';
import { environment } from 'src/environments/environment';
import { AgmOverlays } from 'agm-overlays';
import { LayoutModule } from './layout/layout.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locales/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  /*     AgmCoreModule.forRoot({
      apiKey: environment.apikeyMap,
      libraries: ['places', 'drawing', 'geometry'],
    }), AgmOverlays,
NgwWowModule*/
  /*
   */
  imports: [
    BrowserModule,
    CommonModule,
    RoutesModule,
    HttpClientModule,
    CoreModule,
    LayoutModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    InternationalizationModule.forRoot({ locale_id: 'es-MX' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  /* GoogleMapsAPIWrapper */
  providers: [{ provide: LOCALE_ID, useValue: 'es-*' }],
  bootstrap: [AppComponent],
  /* AgmCoreModule,AgmOverlays */
  exports: [TranslateModule],
})
export class AppModule {}
