import { environment } from '../../../environments/environment';

export class Uris {
  public static API_ENDPOINT_v2 = environment.API_ENDPOINT_V2;
  public static API_ENDPOINT_IMAGE = environment.API_ENDPOINT;
  public static SocketUrl = environment.SOCKET_URL;

  //Charts
  public static CHARTS = `${Uris.API_ENDPOINT_v2}charts/`;
  
  //Account
  public static API_LOGIN = `${Uris.API_ENDPOINT_v2}auth/login/`;
  public static API_LOGOUT = `${Uris.API_ENDPOINT_v2}auth/logout/`;
  public static API_RECOVER_POST = `${Uris.API_ENDPOINT_v2}password/send_request/`;
  public static PASSWORD_CONFIRM = `${Uris.API_ENDPOINT_v2}password/confirm/`;
  public static PASSWORD_UPDATE = `${Uris.API_ENDPOINT_v2}users/update_password/`;
  public static PREFERENCES = `${Uris.API_ENDPOINT_v2}preferences/`;
  public static DASHBORARD = `${Uris.API_ENDPOINT_v2}dashboard/`;
  public static INFORMS = `${Uris.API_ENDPOINT_v2}informs/`;
  public static SETTINGS = `${Uris.API_ENDPOINT_v2}agricola-reports-settings/`;
  

  //Users
  public static API_USERS = `${Uris.API_ENDPOINT_v2}users/`;
  public static API_AGRONODO = `${Uris.API_ENDPOINT_v2}admins/`;
  public static API_AGRICOLA = `${Uris.API_ENDPOINT_v2}agricola/`;
  public static API_ADMIN_AGRICOLA = `${Uris.API_ENDPOINT_v2}adminagricola/`;
  public static API_SUPERVISOR = `${Uris.API_ENDPOINT_v2}supervisor/`;
  public static API_MONITOR = `${Uris.API_ENDPOINT_v2}monitor/`;
  public static API_SPECIALIST = `${Uris.API_ENDPOINT_v2}specialists/`;
  public static API_AGENCY = `${Uris.API_ENDPOINT_v2}agency/`;
  
  //Administration
  public static CROPS = `${Uris.API_ENDPOINT_v2}crops/`;
  public static AGRONODO_A_INCIDENCES = `${Uris.API_ENDPOINT_v2}agricultural-incidences/`;
  public static AGRONODO_T_INCIDENCES = `${Uris.API_ENDPOINT_v2}technical-incidences/`;

  //Fields
  public static API_FIELDS = `${Uris.API_ENDPOINT_v2}fields/`;
  public static API_SECTORS = `${Uris.API_ENDPOINT_v2}sectors/`;
  public static API_CYCLES = `${Uris.API_ENDPOINT_v2}cycles/`;
  public static API_SEASONS = `${Uris.API_ENDPOINT_v2}seasons/`;
  public static API_PATHINGS = `${Uris.API_ENDPOINT_v2}pathings/`;

  //Work related
  public static REPORTS = `${Uris.API_ENDPOINT_v2}reports/`;
  public static API_TASK = `${Uris.API_ENDPOINT_v2}tasks/`;
  public static API_INCIDENCES = `${Uris.API_ENDPOINT_v2}incidences/`;
  public static API_TRACKING = `${Uris.API_ENDPOINT_v2}tracking/`;

  //Resources
  public static RESOURCES_POST = `${Uris.API_ENDPOINT_v2}posts/`;
  public static RESOURCES_ATTACHMENTS = `${Uris.API_ENDPOINT_v2}attachments/`;
  public static RESOURCES_MEDIA = `${Uris.API_ENDPOINT_v2}media/`;
  public static RESOURCES_CATEGORIES = `${Uris.API_ENDPOINT_v2}categories/`;
  public static RESOURCES_LABELS = `${Uris.API_ENDPOINT_v2}labels/`; 

  //Globals
  public static NOTIFICATIONS = `${Uris.API_ENDPOINT_v2}notifications/`;

  //Documents
  public static DOCUMENTS = `${Uris.API_ENDPOINT_v2}documents/`;
}
