<div *ngIf="messageSuccess; else other" [ngClass]="vh" class="d-flex flex-column justify-content-center align-items-center">
     <div class="spinner-border text-primary" role="status">
       <span class="sr-only">{{'loading.loading' | translate}}...</span>
     </div>
     {{'loading.loading' | translate}} {{type}}...
     <small class="text-muted">{{'loading.subTitle' | translate}}</small>
</div>

<ng-template #other>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <i class="fa fa-exclamation-triangle text-danger fa-2x" aria-hidden="true"></i>
      {{'loading.error' | translate}} {{type}}...
      <small class="text-muted">{{'loading.error_sub' | translate}}</small>
    </div>
</ng-template>
