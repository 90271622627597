// Libraries
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';
import { MapOpenLayerComponent } from './mapOL/map';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FilterComponent } from './filter/filter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// Made modules
import { LoadingComponent } from './loading/loading.component';
import { PaginationComponent } from './pagination/pagination.component';
import { IncidenceCardComponent } from './incidence-card/incidence-card.component';
import { ReportComponent } from './report/report.component';
import { ProfileComponent } from './profile/profile.component';
import { MapComponent } from './map/map';
import { InformeComponent } from './informe/informe';
import { CicloUpdateComponent } from './ciclo/ciclo';
import { ImageCropperComponent } from './Image-cropper/image-cropper';
import { ChartComponent } from './chart/chart';
import { GoogleMapsModule } from '@angular/google-maps';
import { InfoWindowComponent } from './map/infowindow/infowindow';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ImageCropperModule,
    TranslateModule,
    NgbModule,
    CarouselModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
  ],
  declarations: [
    LoadingComponent,
    PaginationComponent,
    IncidenceCardComponent,
    ReportComponent,
    ProfileComponent,
    MapComponent,
    InformeComponent,
    CicloUpdateComponent,
    ImageCropperComponent,
    ChartComponent,
    MapOpenLayerComponent,
    FilterComponent,
    InfoWindowComponent
  ],
  exports: [
    LoadingComponent,
    PaginationComponent,
    IncidenceCardComponent,
    ReportComponent,
    ProfileComponent,
    MapComponent,
    InformeComponent,
    CicloUpdateComponent,
    ImageCropperComponent,
    ChartComponent,
    MapOpenLayerComponent,
    FilterComponent,
    NgxDatatableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    CarouselModule,
  ],
  providers: [],
  schemas:  [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule {}
