<!-- SIN REPORTE -->
<div
  class="card center"
  *ngIf="incidence && incidence.report === null"
  style="height: 300px"
>
  <p class="p5">{{ "generals.no_active" | translate }}</p>
</div>

<!-- SIN INCIDENCIA -->
<div *ngIf="!incidence" class="center p-4">
  <!-- <p>No hay un tipo de incidencia activa</p> -->
</div>

<div
  class="card wow fadeInRight"
  *ngIf="incidence && incidence.report !== null"
>
  <div class="card-header bg-dark text-white">
    <div class="row">
      <div class="col-12 d-flex flex-column justify-content-center">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <small>
            <b>{{ "incidences_view.order" | translate }}: </b
            >{{ incidence.index ? incidence.index + 1 : 1 }}
          </small>
          <small> <b>ID: </b>{{ incidence.id ? incidence.id : "??" }} </small>
        </div>
        <div class="mb-2">
          <h4 class="mb-0">{{ incidence.report?.title }}</h4>
          <small
            >{{ incidence.datetime | date : "dd-mm-yyyy 'a las' h:MM:ss" }}
          </small>
        </div>
        <small>
          <b>{{ "generals.description" | translate }}: </b
          >{{ incidence.description ? incidence.description : "??" }}
        </small>
        <small>
          <b>{{ "incidences.observation" | translate }}: </b
          >{{
            incidence.report?.observation
              ? incidence.report?.observation
              : ("incidences.no_observation" | translate)
          }}
        </small>
      </div>
    </div>
  </div>

  <div class="px-3 py-0">
    <!-- CONTENIDO -->
    <ng-container *ngIf="hasProp(incidence.report, 'incidence')">
      <div class="row" *ngIf="incidence && !incidence.report.incidences">
        <div class="col-12">
          <h3 class="mb-4 mt-1">{{ "reports.healthy" | translate }}</h3>
        </div>
        <div class="col-6 d-flex flex-column justify-content-center">
          <p>
            <b>{{ "table.title" | translate }}: </b
            >{{ incidence.report?.title }}
          </p>
          <p>
            <b>{{ "table.date" | translate }}: </b
            >{{ incidence.report.datetime | date : "medium" }}
          </p>
          <p>
            <b>{{ "generals.description" | translate }}: </b
            >{{ incidence.description ? incidence.description : "??" }}
          </p>
          <p>
            <b>{{ "incidences.observation" | translate }}: </b
            >{{
              incidence.report?.observation
                ? incidence.report?.observation
                : ("incidences.no_observation" | translate)
            }}
          </p>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
          <img
            alt="tecnica"
            src="assets/img/icons/tecnica.png"
            style="width: 40%"
          />
        </div>
        <div class="col-2 center text-center flex-column">
          <div class="finalizado">
            <p class="mb-0">
              <b>{{ "task.finished" | translate }}</b>
            </p>
            <i
              class="fa fa-2x fa-check-circle text-primary"
              *ngIf="incidence.done"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-2x fa-ban text-danger"
              *ngIf="!incidence.done"
              aria-hidden="true"
            ></i>
          </div>
          <div class="orden mt-3">
            <p class="mb-0">
              <b>{{ "task.task_id" | translate }}</b>
            </p>
            <h5>{{ incidence.task_id ? incidence.task_id : "N/A" }}</h5>
          </div>
          <div class="id mt-3">
            <p class="mb-0">
              <b>{{ "task.objective_id" | translate }}</b>
            </p>
            <h5>
              {{
                incidence.task_objective_id
                  ? incidence.task_objective_id
                  : "N/A"
              }}
            </h5>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row mt-2" *ngIf="incidence && incidence.agricultural_incidence">
      <div class="col-12 d-flex flex-row justify-content-around">
        <div class="left d-flex center flex-column">
          <img
            alt="agricola"
            [src]="
              imagePlant(
                incidence.agricultural_incidence?.ground,
                incidence.agricultural_incidence?.plant,
                incidence.agricultural_incidence?.fruit
              )
            "
            width="auto"
            height="100px"
          />
          <small
            >{{ "incidences.amount" | translate }}:
            <b>{{
              incidence.agricultural_incidence?.amount
                ? incidence.agricultural_incidence?.amount
                : "??"
            }}</b></small
          >
        </div>
        <div class="right">
          <div class="center flex-column">
            <div class="row">
              <div class="col-12 text-center">
                <div *ngIf="getAgriculturalIncidenceItem('fruit', 'fas fa-square', 'incidences.fruit')" class="icon d-flex flex-column">
                  <a class="fas fa-2x fa-square" [ngStyle]="{'color': getAgriculturalIncidenceItem('fruit', 'fas fa-square', 'incidences.fruit').color}"></a>
                  <small>{{getAgriculturalIncidenceItem('fruit', 'fas fa-square', 'incidences.fruit').translationKey | translate}}</small>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center">
                <div *ngIf="getAgriculturalIncidenceItem('plant', 'fas fa-square', 'incidences.plant')" class="icon d-flex flex-column">
                  <a class="fas fa-2x fa-square" [ngStyle]="{'color': getAgriculturalIncidenceItem('plant', 'fas fa-square', 'incidences.plant').color}"></a>
                  <small>{{getAgriculturalIncidenceItem('plant', 'fas fa-square', 'incidences.plant').translationKey | translate}}</small>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center">
                <div *ngIf="getAgriculturalIncidenceItem('ground', 'fas fa-square', 'incidences.soil')" class="icon d-flex flex-column">
                  <a class="fas fa-2x fa-square" [ngStyle]="{'color': getAgriculturalIncidenceItem('ground', 'fas fa-square', 'incidences.soil').color}"></a>
                  <small>{{getAgriculturalIncidenceItem('ground', 'fas fa-square', 'incidences.soil').translationKey | translate}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row p-3 mt-2" *ngIf="incidence && incidence.technical_incidence">
      <div class="col-lg-6 col-sm-12 center text-center">
        <h3 class="mb-0">{{ incidence.technical_incidence.name.name }}</h3>
      </div>
      <div class="col-lg-6 col-sm-12 center">
        <img
          alt="tecnica"
          src="assets/img/icons/tecnica.png"
          style="width: 40%"
        />
      </div>
    </div>
    <!-- MULTIMEDIA -->
    <ng-container *ngIf="incidence && validateObject(incidence.report)">
      <div class="row center mt-2">
        <!-- SIN IMAGENES -->
        <div class="col-12 center" *ngIf="incidence.report.images.length === 0">
          <div class="my-4 center text-center">
            <p class="text-danger m-0">
              {{ "warnings.no_image" | translate }}
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </p>
          </div>
        </div>
        <!-- VARIAS IMAGENES -->
        <div class="carousel" *ngIf="incidence.report.images.length > 1">
          <owl-carousel-o [options]="customOptions">
            <ng-container
              *ngFor="let slide of incidence.report?.images; let i = index"
            >
              <ng-template carouselSlide [id]="i">
                <a target="_blank" [href]="slide.image">
                  <img [src]="slide.image" alt="" height="250" />
                </a>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <!-- UNA SOLA IMAGEN -->
        <div class="single-img" *ngIf="incidence.report.images.length === 1">
          <a target="_blank" [href]="incidence.report.images[0].image">
            <img
              class="pos-image"
              [src]="incidence.report.images[0].image"
              width="250"
            />
          </a>
        </div>
      </div>

      <!-- MULTIMEDIA -->
      <div class="p-2 mt-2">
        <!-- SIN MULTIMEDIA -->
        <ng-container *ngIf="multimedia == 0">
          <div class="my-4 center text-center">
            <p class="text-danger m-0">
              {{ "warnings.no_multimedia" | translate }}
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </p>
          </div>
        </ng-container>
        <!-- AUDIO -->
        <div
          class="center"
          *ngIf="multimedia == 1 && validateObject(incidence.report)"
        >
          <video
            *ngIf="incidence.report.audios.length > 0"
            [src]="incidence.report?.audios[0].audio"
            type="video/ogg;"
            class="w-100 multimedia-control"
            codecs="mp4v.20.8, samr"
            controls
            height="60"
          >
            Tu navegador no admite el elemento <code>video</code>.
          </video>
        </div>
        <!-- VIDEO -->
        <div class="center" *ngIf="multimedia == 2">
          <div style="height: 320px" class="w-100">
            <video
              controls
              [src]="incidence.report?.videos[0].video"
              class="w-100 multimedia-control"
              type="video/ogg;"
              codecs=" mp4v.20.8, samr"
              style="height: 100%"
            >
              Tu navegador no admite el elemento <code>video</code>.
            </video>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
