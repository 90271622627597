import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../core/settings/settings.service';
import { ThemesService } from '../core/themes/themes.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  currentTheme: any;
  toggle = false;
  constructor(public themes: ThemesService, public settings: SettingsService) {
    this.currentTheme = themes.getDefaultTheme();
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    const isMobileOrTablet = window.innerWidth < 980;
    this.setCollapsedState(isMobileOrTablet);
  }

  setCollapsedState(isMobileOrTablet: boolean) {
    if (isMobileOrTablet) {
      this.settings.setLayoutSetting('isCollapsed', true);
    } else {
      let mode = Number(localStorage.getItem('menuMode'));
      if (mode == 1) {
        this.settings.setLayoutSetting('isCollapsed', false);
      } else {
        this.settings.setLayoutSetting('isCollapsed', true);
      }
    }
  }

  validateToggle() {
    let bool = this.settings.getLayoutSetting('isCollapsed');
    return bool ? 'aside-container-toggle' : 'aside-container';
  }

  validateToggleContent() {
    let bool = this.settings.getLayoutSetting('isCollapsed');
    return bool
      ? 'section-container section-container-toggle'
      : 'section-container';
  }
}
