import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { UserblockService } from './userblock.service';
import { AdminAgronodo } from 'src/app/Services/agronodo/admin-agronodo.service';
import { GlobalFunction } from 'src/app/Services/public/globalFunction';
import { AuthService } from 'src/app/Services/public/auth.service';
import { GlobalServices } from 'src/app/Services/shared/global.service';
import { ProfileChangeService } from 'src/app/components/profile/profile_changes.service';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/Services/shared/storage.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent implements OnInit {
  @ViewChild('longContent') public longContent;
  @Output('event') event = new EventEmitter();
  @Input() getModal;
  config = {
    backdrop: true,
    class: 'modal-xl modal-config modal-content',
  };

  public user: any;
  public photo: string;

  modal = false;
  imagenLocal = 'assets/img/logo.png';

  localStorageItem: string;
  modeSubsciption: Subscription;
  profileChangesSubscription: Subscription;
  constructor(
    public profileChangeService: ProfileChangeService,
    public userblockService: UserblockService,
    public globalFunctions: GlobalFunction,
    public storageService: StorageService,
    public globalServices: GlobalServices,
    public adminService: AdminAgronodo,
    public settings: SettingsService,
    public AuthService: AuthService
  ) {}

  ngOnInit() {
    this.getProfile();
    this.modeSubsciption = this.storageService
      .watchStorage('mode')
      .subscribe(async (mode) => {
        this.getProfile();
      });

    this.profileChangesSubscription =
      this.profileChangeService.localStorageChanges$.subscribe(
        (key: string) => {
          this.getProfile();
        }
      );
  }

  getProfile = () => {
    this.AuthService.getProfile().subscribe((resp: any) => {
      this.user = resp;
      resp.profile.photo ? (this.photo = resp.profile.photo) : this.imagenLocal;
    });
  };

  userBlockIsVisible() {
    return true;
  }

  openVerticallyCentered() {
    this.globalFunctions.Modal(this.longContent, 'show', {
      size: 'xl',
      centered: true,
      scrollable: true,
    });
  }
}
