import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { AuthService } from 'src/app/Services/public/auth.service';
import { GlobalFunction } from 'src/app/Services/public/globalFunction';
import { ProfileChangeService } from 'src/app/components/profile/profile_changes.service';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/Services/shared/storage.service';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('profileModal') public profileModal;

  menuItems: Array<any>;
  router: Router;
  sbclickEvent = 'click.sidebar-toggle';
  $doc: any = null;
  profile = false;
  modeSubsciption: Subscription;

  selectedMenuItem: any;

  constructor(
    public menu: MenuService,
    public settings: SettingsService,
    public injector: Injector,
    public AuthService: AuthService,
    public route: ActivatedRoute,
    public GlobalFunction: GlobalFunction,
    public profileChangeService: ProfileChangeService,
    public storageService: StorageService
  ) {}

  ngOnInit() {
    this.validateMenu();

    this.modeSubsciption = this.storageService
      .watchStorage('mode')
      .subscribe(async (mode) => {
        this.validateMenu();
      });

    setTimeout(() => {
      this.router = this.injector.get(Router);

      this.router.events.subscribe((val) => {
        // close any submenu opened when route changes
        this.removeFloatingNav();
        // scroll view to top
        window.scrollTo(0, 0);
        // close sidebar on route change
        this.settings.setLayoutSetting('asideToggled', false);
      });
      // enable sidebar autoclose from extenal clicks
      this.anyClickClose();
    }, 500);
  }

  validateMenu = () => {
    const url = this.route.snapshot['_routerState'].url;

    const updateMenuItem = (item: any) => {
      if (item.submenu && item.submenu.length > 0) {
        const activeSubmenu = item.submenu.find(
          (submenu: any) => submenu.link === url
        );
        const updatedSubmenu = item.submenu.map((submenu: any) => ({
          ...submenu,
          active: submenu.link === url,
        }));

        if (activeSubmenu) {
          activeSubmenu.active = true;
          this.selectedMenuItem = activeSubmenu;
        }

        return {
          ...item,
          submenu: updatedSubmenu,
        };
      } else {
        if (item.link === url) {
          item.active = true;
          this.selectedMenuItem = item;
        }

        return item;
      }
    };

    this.menuItems = this.menu.getMenu().map(updateMenuItem);
  };

  anyClickClose() {
    this.$doc = $(document).on(this.sbclickEvent, (e) => {
      if (!$(e.target).parents('.aside-container').length) {
        this.settings.setLayoutSetting('asideToggled', false);
      }
    });
  }

  ngOnDestroy() {
    if (this.$doc) this.$doc.off(this.sbclickEvent);
  }

  toggleSubmenuClick(menuItemData: any) {
    if (this.selectedMenuItem) this.selectedMenuItem.active = false;
    this.selectedMenuItem = menuItemData;

    if (menuItemData.text === 'menu.profile') {
      this.GlobalFunction.Modal(this.profileModal, 'show', {
        size: 'xl',
        centered: true,
      });
    } else {
      menuItemData.active = true;
    }
  }

  listenForExternalClicks() {
    let $doc = $(document).on('click.sidebar', (e) => {
      if (!$(e.target).parents('.aside-container').length) {
        this.removeFloatingNav();
        $doc.off('click.sidebar');
      }
    });
  }

  removeFloatingNav() {
    $('.opening').remove();
  }

  isSidebarCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }

  isSidebarCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  isEnabledHover() {
    return this.settings.getLayoutSetting('asideHover');
  }

  logout() {
    this.AuthService.logout().subscribe((resp) => {
      this.router.navigateByUrl(`/login`);
    });
  }

  mouseleaveActive(menu, type) {
    if (type === 'leave') {
      if (this.settings.getLayoutSetting('isCollapsed')) {
        this.menuItems[menu].show = false;
      }
    }
  }

  validateClass(active, show) {
    if (this.settings.getLayoutSetting('isCollapsed')) {
      if (show && active) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
