<!-- <div class="chart-wrapper">
  <canvas
    [class]="classText ? classText : ''"
    baseChart
    [datasets]="data"
    [labels]="labels"
    [options]="options"
    [colors]="lineColors"
    [legend]="lineChartLegend"
    [chartType]="type"
    [plugins]="lineChartPlugins"
  >
  </canvas>
</div> -->
<button class="btn btn-outline-primary btn-sm" (click)="update()">
  <i class="fas fa-redo-alt"></i>
</button>
