import { GlobalFunction } from './../Services/public/globalFunction';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable()
export class AgricolaGuard  {
  constructor(
    private location: Location,
    private router: Router,
    public globalFunction: GlobalFunction
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = JSON.parse(window.localStorage.getItem('USER'));
    const url = state.url;

    const userTypesPermissions = {
      4: [
        { url: '/tareas', permission: 'prms_tasks_bool' },
        { url: '/admin', permission: 'prms_users_extra_bool' },
        { url: '/supervisores', permission: 'prms_users_extra_bool' },
        { url: '/monitor', permission: 'prms_users_extra_bool' },
        { url: '/fields', permission: 'prms_fields_bool' },
      ],
      5: [
        { url: '/admin', permission: 'prms_admin_bool' },
        { url: '/supervisor', permission: 'prms_supervisor_bool' },
        { url: '/fields', permission: 'prms_fields_bool' },
      ],
      6: [
        { url: '/reportes_individuales', permission: 'prms_reports_bool' },
        { url: '/monitor', permission: 'prms_monitors_bool' },
        { url: '/fields', permission: 'prms_fields_bool' },
        { url: '/tareas', permission: 'prms_tasks_bool' },
      ],
    };

    if (user.user_type == 4) { return true };

    if (userTypesPermissions[user.user_type]) {
      const permissions = userTypesPermissions[user.user_type];

      if (permissions) {
        const noPermission = permissions.some(
          (p) => url.includes(p.url) && !user.profile[p.permission]
        );

        if (noPermission) {
          this.globalFunction.animation(
            'error',
            'No tienes permisos para acceder a esta página',
            'Error'
          );
          this.router.navigate(['/agronodo/home']);
          return false;
        }
      }

      return true;
    }

    this.location.back();
    return false;
  }
}
