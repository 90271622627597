import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Uris } from './Uris';
import { map, catchError } from 'rxjs/operators';
import { SwalService } from './swal.service';
import { GlobalFunction } from './globalFunction';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userToken: String = '';

  constructor(
    private http: HttpClient,
    public Swal: SwalService,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {
    this.leerToken();
  }
  
  headersAuthBase64(value) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'es',
      Authorization:
        'Basic ' + `${btoa(`${value.username}:${value.password}`)})`,
    });
    return { headers: headers };
  }
  
  login(admin: any) {
    return this.http
      .post(`${Uris.API_LOGIN}`, {}, this.headersAuthBase64(admin))
      .pipe(
        map((resp: any) => {
          if (resp.user.user_type === 7) {
          } else {
            this.guardarToken(resp['token']);
            localStorage.setItem('USER', JSON.stringify(resp.user));
          }
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  recover(email: string) {
    return this.http.post(`${Uris.API_RECOVER_POST}`, email).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((err: any) => {
        return this.GlobalFunction.validateError(err);
      })
    );
  }

  updatePassword(password: any) {
    return this.http.post(`${Uris.PASSWORD_UPDATE}`, password, this.GlobalFunction.headers()).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((err: any) => {
        return this.GlobalFunction.validateError(err);
      })
    );
  }

  passwordConfirm(password: any) {
    return this.http.post(`${Uris.PASSWORD_CONFIRM}`, password).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((err: any) => {
        return this.GlobalFunction.validateError(err);
      })
    );
  }

  getProfile() {
    return this.http
      .get(`${Uris.API_USERS}profile/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
  
  logout() {
    return this.http
      .post(`${Uris.API_LOGOUT}`, {}, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
  
  generateTemporalAccess(id) {
    return this.http
      .get(
        `${Uris.API_AGRICOLA}${id}/generate_temporal_access/`,
        this.GlobalFunction.headers()
      )
      .pipe(
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        }),
        map((resp: any) => {
          return resp;
        })
      )
  }

  private guardarToken(idToken: string) {
    this.userToken = idToken;

    localStorage.setItem('token', idToken);
  }

  leerToken() {
    return localStorage.getItem('token');
  }
}
