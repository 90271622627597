import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Uris } from '../public/Uris';
import { GlobalFunction } from '../public/globalFunction';
@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}
  
  getAgencies(query) {
    return this.http
      .get(`${Uris.API_AGENCY}${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getAgency(id: any) {
    return this.http
      .get(`${Uris.API_AGENCY}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  postAgency(obj: any) {
    return this.http
      .post(`${Uris.API_AGENCY}`, obj, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  edit(agency: any, id: string) {
    return this.http
      .patch(`${Uris.API_AGENCY}${id}/`, agency , this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  deleteAgency(id: any) {
    return this.http
      .delete(`${Uris.API_AGENCY}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  assignSpecialistToAgency(obj) {
    return this.http
      .post(`${Uris.API_AGENCY}assign_specialist_to_agency/`, obj, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  
  removeSpecialistFromAgency(id) {
    return this.http
      .post(`${Uris.API_AGENCY}${id}/remove_specialist_from_agency/`, {}, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getAgencySpecialists(id) {
    return this.http
      .get(`${Uris.API_AGENCY}${id}/agency_specialists/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getAgencyClients(id) {
    return this.http
      .get(`${Uris.API_AGENCY}${id}/agency_clients/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  assignClientsToAgency(obj) {
    return this.http
      .post(`${Uris.API_AGENCY}assign_client_to_agency/`, obj, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  
  removeClientsFromAgency(id) {
    return this.http
      .post(`${Uris.API_AGENCY}${id}/remove_client_from_agency/`, {}, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getMySpecialists() {
    return this.http
      .get(`${Uris.API_AGENCY}my_specialists/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getMyClients() {
    return this.http
      .get(`${Uris.API_AGENCY}my_clients/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  getMySpecialistClients(id) {
    return this.http
      .get(`${Uris.API_AGENCY}${id}/my_specialists_clients/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
}
