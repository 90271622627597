import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Uris } from 'src/app/Services/public/Uris';
import * as cloneDeep from 'lodash/cloneDeep';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-report-incidence-view',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  private _incidence: any;

  multimedia: number = 0;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
    nav: true,
  };

  incidences = {
    agricultural_incidence: {
      fruit: 'red',
      plant: 'green',
      ground: 'brown',
    },
  };

  @Input() set incidence(incidence: any) {
    if (incidence) {
      this._incidence = incidence;
    }
  }

  constructor() {}

  get incidence(): any {
    return this._incidence;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { incidence } = changes;
    if (incidence) {
      /* Imagenes y (audios o videos)  */
      if (this.validateObject(incidence.currentValue.report)) {
        if (incidence.currentValue.report.audios.length > 0) {
          this.multimedia = 1;
        } else if (incidence.currentValue.report.videos.length > 0) {
          this.multimedia = 2;
        }
      } else {
        this.multimedia = 0;
      }
    }
  }

  getAgriculturalIncidenceItem(
    property: string,
    iconName: string,
    translationKey: string
  ) {
    if (
      this.incidence.agricultural_incidence &&
      this.incidence.agricultural_incidence[property]
    ) {
      const colorValue = this.color(
        this.incidence.agricultural_incidence[property]
      );
      return {
        color: colorValue,
        translationKey: translationKey,
      };
    }
    return null;
  }

  hasProp(object: any, name: string) {
    if (object) {
      return object.hasOwnProperty(name);
    } else {
      return false;
    }
  }

  color(value) {
    switch (value) {
      case 1:
        return '#87c933';
      case 2:
        return '#FFFF00';
      case 3:
        return '#cb3234';
      default:
        return '#87c933';
    }
  }

  imagePlant(ground, plant, fruit) {
    if (fruit && plant && ground) {
      return `assets/img/plant/${ground}_${plant}_${fruit}.png`;
    }
    return '';
  }

  validateObject(object) {
    let type = typeof object;
    return type === 'object' ? true : false;
  }
}
