import {
  Component,
  Injectable,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ChartDataset } from 'chart.js';
import { EventEmitter } from 'events';
import { BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'app-chart',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class ChartComponent implements OnInit {
  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;
  @Input() data: ChartDataset[] = [];
  @Input() type;
  @Input() options;
  @Input() classText;

  @Output('update') event = new EventEmitter();

  /* Parte Vertical  [{ data: [85, 72, 78, 75, 77, 75], label: 'Crude oil prices' }] */

  @Input() labels = [];
  /* Parte horizontal  ['January', 'February', 'March', 'April', 'May', 'June'] */

  @Input() lineColors: any[] = [];
  /*  { borderColor: 'black', backgroundColor: 'rgba(255,255,0,0.28)', },  https://www.chartjs.org/docs/latest/charts/line.html*/

  lineChartLegend: any = true;
  lineChartPlugins: any = [];
  ngOnInit() {}

  update() {
    this.chart.chart.update();
  }
}
