import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { Router } from '@angular/router';
import { GlobalFunction } from 'src/app/Services/public/globalFunction';
import { SocketService } from 'src/app/Services/socket/notification.service';
import { ToastrService } from 'ngx-toastr';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { TutorialsService } from 'src/app/Services/public/tutorials.service';
import * as introJs from 'intro.js';
import { Title } from '@angular/platform-browser';
import { Subscription, interval } from 'rxjs';
import { Uris } from 'src/app/Services/public/Uris';
import { StorageService } from 'src/app/Services/shared/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  user: any;
  isNavSearchVisible: boolean;
  mostar = false;
  notifications = [];
  active = true;
  @ViewChild('ListNotification') ListNotification: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('icon') icon: ElementRef;

  introJS = introJs();

  selectedLenguage: string = 'es-MX';

  mobile = false;

  public href: string = '';

  isAdmin: boolean = false;

  mode = 0;
  client = null;
  modeSubsciption: Subscription;

  constructor(
    private localizationService: LocalizationService,
    private tutorialsService: TutorialsService,
    public userblockService: UserblockService,
    public storageService: StorageService,
    public globalFunction: GlobalFunction,
    public SocketService: SocketService,
    public settings: SettingsService,
    public toastr: ToastrService,
    public menu: MenuService,
    private router: Router,
    private title: Title
  ) {
    const msg = this.SocketService.socket();
  }

  ngOnDestroy(): void {
    this.modeSubsciption.unsubscribe();
  }

  changeLenguage() {
    this.selectedLenguage =
      this.selectedLenguage == 'es-MX' ? 'en-US' : 'es-MX';
    localStorage.setItem('language', this.selectedLenguage);
    this.localizationService.initService();
  }
  titleNumber = true;
  ngOnInit() {
    console.log('estamos en develop', environment.API_ENDPOINT);
    let user = <any>JSON.parse(localStorage.getItem('USER') || '{}');

    let mode = Number(localStorage.getItem('mode'));
    this.mode = mode;

    const validateClient = () => {
      let client = <any>JSON.parse(localStorage.getItem('access'));
      if (client) {
        return client;
      }
      return null;
    };

    this.client = validateClient();

    this.modeSubsciption = this.storageService
      .watchStorage('mode')
      .subscribe(async (mode) => {
        this.mode = Number(mode);
        this.client = validateClient();
      });

    if (user.user_type == 2) {
      this.isAdmin = true;
    } else {
      const second = interval(2000);

      second.subscribe(() => {
        this.title.setTitle(
          `${
            this.titleNumber && this.notifications.length > 0
              ? `(${this.notifications.length}) `
              : ''
          } Agronodo`
        );
        this.titleNumber = !this.titleNumber;
      });

      this.getNotification();
    }

    if (!localStorage.getItem('language')) {
      this.selectedLenguage = 'es-MX';
    } else {
      this.selectedLenguage = localStorage.getItem('language');
    }
    this.mobile = this.globalFunction.isPhone();

    /*   if (window.innerWidth < 1000) {
      this.settings.toggleLayoutSetting('isCollapsed');

    } */

    this.isNavSearchVisible = false;
  }

  onChangeMode() {
    let mode = this.mode === 1 ? 0 : 1;
    localStorage.setItem('mode', mode.toString());
    this.storageService.setItem('mode', mode);
    this.storageService.validateUrl();
  }

  getNotification() {
    let query = `?ordering=-datetime&read=0`;
    this.SocketService.getNotifications(query).subscribe((resp: any) => {
      if (resp.length === 0) {
        this.active = true;
      }
      this.notifications = resp;
    });
  }

  getHelp() {
    this.introJS
      .setOptions({
        steps: this.tutorialsService.currentHelp,
        showProgress: true,
      })
      .start();
  }

  reproducir() {
    const audio = new Audio('assets/sound/bop.mp3');
    audio.play();
  }
  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    localStorage.setItem(
      'menuMode',
      this.settings.layout.isCollapsed ? '1' : '0'
    );
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  mostrarAlert() {
    return this.mostar;
  }

  show() {
    if (this.mobile) {
      this.router.navigate(['/agricola/notificaciones']);
    } else {
      this.active = !this.active;
    }
  }
  returnType(type: number) {
    return this.globalFunction.getNameNotification(type);
  }
  maskasread(item) {
    this.SocketService.NotificationsMarkAsRead({ ids: [item.id] }).subscribe(
      (item) => {
        this.getNotification();
      }
    );
  }
}
