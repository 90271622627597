import { Injectable } from '@angular/core';
import { Uris } from '../public/Uris';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalFunction } from '../public/globalFunction';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminAgricola {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}

  register(adminAgricola): Observable<any> {
    return this.http
      .post(
        `${Uris.API_ADMIN_AGRICOLA}`,
        adminAgricola,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: HttpErrorResponse) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  listadmin(query) {
    return this.http
      .get(`${Uris.API_ADMIN_AGRICOLA}${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
  getAdmin(id) {
    return this.http
      .get(`${Uris.API_ADMIN_AGRICOLA}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  delete(id) {
    return this.http
      .delete(`${Uris.API_ADMIN_AGRICOLA}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  edit(adminAgricola, id) {
    return this.http
      .patch(
        `${Uris.API_ADMIN_AGRICOLA}${id}/`,
        adminAgricola,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
}
