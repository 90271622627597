<!-- START Top Navbar-->
<nav class="navbar topnavbar d-flex justify-content-center" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header text-center" style="width: 56px">
    <a class="navbar-brand" class="text-white">
      <div style="font-size: 17px" style="width: 56px">
        <img
          class="img-fluid"
          src="assets/img/logo.png"
          alt="App Logo"
          style="height: 46px"
        />
      </div>
    </a>
  </div>

  <!-- END navbar header-->
  <!-- START Left navbar-->

  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item" (click)="toggleCollapsedSideabar()">
      <a class="nav-link" trigger-resize="">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </a>
    </li>
    <ng-container>
      <li class="nav-item d-none d-md-block">
        <a class="nav-link" routerLink="change_password">
          <i class="fa fa-lock" aria-hidden="true"></i>
        </a>
      </li>
      <li
        *ngIf="!isAdmin"
        class="nav-item d-none d-md-block"
        (click)="getHelp()"
      >
        <a class="nav-link" trigger-resize="">
          <i class="far fa-" aria-hidden="true"></i>
        </a>
      </li>
    </ng-container>

    <li
      class="nav-item d-flex justify-content-center align-items-center"
      *ngIf="client"
    >
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          [checked]="mode === 1"
          style="visibility: hidden"
          id="ChangeUserSw"
          (change)="onChangeMode()"
        />
        <label class="custom-control-label" for="ChangeUserSw"
          >{{ client.user_type_name || "" }}
          {{ client.profile ? client.profile.name : "" }}
        </label>
      </div>
    </li>
  </ul>

  <ul class="navbar-nav flex-row margin-options" style="z-index: 1">
    <li class="center p-3">
      <a
        href="https://wa.me/+526688201605"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="assets/img/whatsapp.png"
          class="c-pointer"
          alt="whatsapp"
          style="height: 20px"
        />
      </a>
    </li>
    <li class="center p-3" (click)="changeLenguage()" style="cursor: pointer">
      <p class="mb-0 mr-1">{{ selectedLenguage === "es-MX" ? "ESP" : "US" }}</p>
      <img
        src="assets/img/flags/{{
          selectedLenguage === 'es-MX' ? 'mx.png' : 'us.png'
        }}"
        width="20px"
        alt="{{
          selectedLenguage === 'es-MX' ? 'mexican flag' : 'united states flag'
        }}"
      />
    </li>

    <button
      class="btn shop nav-link p-3"
      [disabled]="notifications.length === 0"
      (click)="show()"
      #toggleButton
      [ngClass]="{ active: !active }"
    >
      <i class="fas fa-bell" #icon></i>
      <p class="shop-text">{{ notifications.length }}</p>
    </button>
  </ul>
</nav>

<ng-container *ngIf="!active && !isAdmin">
  <div class="notification wow fadeInDown" #ListNotification>
    <div class="card" style="width: 652px">
      <div
        class="card-header text-light bg-primary"
        style="width: inherit; z-index: 1; position: fixed"
      >
        <div class="row">
          <div class="col-6">
            <h5 class="m-0">
              {{ "notification.notification_header" | translate }}
              <i class="fa fa-bell" aria-hidden="true"></i>
            </h5>
          </div>
          <div class="col-6 text-right">
            <i class="fa fa-times" (click)="show()" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="card-body bg-white" style="margin-top: 70px">
        <div
          class="row"
          *ngFor="let item of notifications; let i = index"
          (click)="maskasread(item)"
        >
          <div class="col-1 d-flex align-items-center">
            <h2>{{ i }}</h2>
          </div>
          <div class="col-8">
            <p class="m-0">{{ returnType(item.type) }}</p>
            <p class="m-0">
              {{ item.type === 1 ? item.data.message : item.message }}
            </p>
          </div>
          <div class="col-3 text-right">
            {{ item.datetime | date : "EEEE, MMMM d, y HH:mm:ss" }}
          </div>
          <div class="col-12">
            <hr
              style="border: 1px solid rgba(0, 0, 0, 0.09); margin-bottom: 1px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- END Top Navbar-->
