import { Location } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {  FormControl,  FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FieldsAgricolaService } from 'src/app/Services/agricola/fields-agricola.service';
import { GlobalFunction } from 'src/app/Services/public/globalFunction';

@Component({
  selector: 'app-ciclo',
  templateUrl: './ciclo.html',
  styleUrls: ['./ciclo.scss'],
})
export class CicloUpdateComponent implements OnInit {
  @Input('sublote') sublote: any;
  @Input('cicle') cicle: any;
  @Input('type')
  type: boolean; /* true si agregara nuevo ciclo, false para editar nuevo ciclo */

  @Output('success') succesPetition = new EventEmitter<string>();
  cicloForm:  FormGroup;
  constructor(
    public fieldsService: FieldsAgricolaService,
    public GlobalFuncion: GlobalFunction,
    public router: Router,
    public Location: Location
  ) {
    this.cicloForm = new  FormGroup({
      start_date: new  FormControl('', Validators.required),
      finish_date: new  FormControl('', Validators.required),
    });
  }

  hideModal(cicle: any) {
    if (cicle.progress === '') {
      this.Location.back();
      this.GlobalFuncion.Modal('', 'hide', {});
    } else {
      this.GlobalFuncion.Modal('', 'hide', {});
    }
  }
  ngOnInit(): void {
    if (this.type) {
    } else {
      this.cicloForm.controls['start_date'].disable();
      let { start_date, finish_date } = this.cicle;
      this.cicloForm.setValue({ start_date, finish_date });
    }
  }

  ciclo(subId, cicle, value) {
    if (this.type) {
      this.fieldsService.cycleRegister(value).subscribe((resp: any) => {
        this.succesPetition.emit(resp);
      });
    } else {
      this.fieldsService.editCycle(cicle.id, {
        ...value,
        start_date: this.cicloForm.controls['start_date'].value,
      }).subscribe((resp) => {
        window.location.reload();
      });
    }
  }
}
