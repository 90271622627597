import { Component, Input, OnInit } from '@angular/core';
import { InformsService } from 'src/app/Services/agricola/informs.service';
import { GlobalFunction } from 'src/app/Services/public/globalFunction';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SupervisorService } from 'src/app/Services/agricola/supervisor.service';

@Component({
  selector: 'app-inform',
  templateUrl: './informe.html',
  styleUrls: ['./informe.scss'],
})
export class InformeComponent implements OnInit {
  @Input('data') data: any;
  @Input('type') type: any;

  users: any = [];
  informsGroup: FormGroup;

  validateRequisitions = false;

  loadingInform: boolean = false;

  constructor(
    public supervisorService: SupervisorService,
    public informsService: InformsService,
    public globalFunction: GlobalFunction,
    private fb: FormBuilder,
    public router: Router
  ) {
    this.informsGroup = this.fb.group({
      dateOption: 'specific', // Default value
      start_date: new FormControl(''),
      finish_date: new FormControl(''),
      user: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.datesControls();
    this.supervisorService.listSupervisor('').subscribe((res) => {
      this.users = res;
    });
  }

  setToday() {}

  datesControls() {
    this.informsGroup
      .get('dateOption')
      .valueChanges.subscribe((option: string) => {
        const today = new Date();
        switch (option) {
          case 'specific':
            this.informsGroup.patchValue({
              start_date: '',
              finish_date: '',
            });
            break;
          case 'today':
            this.informsGroup.patchValue({
              start_date: this.formatDate(today),
              finish_date: this.formatDate(today),
            });
            break;
          case 'last7Days':
            const sevenDaysAgo = new Date(today);
            sevenDaysAgo.setDate(today.getDate() - 7);
            this.informsGroup.patchValue({
              start_date: this.formatDate(sevenDaysAgo),
              finish_date: this.formatDate(today),
            });
            break;
          case 'thisWeek':
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay() + 1);
            const endOfWeek = new Date(today);
            endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
            this.informsGroup.patchValue({
              start_date: this.formatDate(startOfWeek),
              finish_date: this.formatDate(endOfWeek),
            });
            break;
          case 'thisMonth':
            const startOfMonth = new Date(
              today.getFullYear(),
              today.getMonth(),
              1
            );
            const endOfMonth = new Date(
              today.getFullYear(),
              today.getMonth() + 1,
              0
            );
            this.informsGroup.patchValue({
              start_date: this.formatDate(startOfMonth),
              finish_date: this.formatDate(endOfMonth),
            });
            break;
          case 'currentCycle':
            this.informsGroup.patchValue({
              start_date: '',
              finish_date: '',
            });
            break;
          default:
            break;
        }
      });
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  generateInform = async (val) => {
    if (
      val.dateOption !== 'currentCycle' && // Check if the option is not currentCycle
      !((val.start_date && val.finish_date) || val.user)
    ) {
      this.globalFunction.animation(
        'warning',
        'Alerta',
        'Asegurate de llenar los campos'
      );
      return;
    }

    const currentStatusQuery = `?current_status=${
      val.dateOption == 'currentCycle' ? 'true' : 'false'
    }`;
    const startDateQuery = val.start_date
      ? `&start_date=${val.start_date}`
      : '';
    const finishDateQuery = val.finish_date
      ? `&finish_date=${val.finish_date}`
      : '';
    const userQuery = val.user ? `&target_user=${val.user}` : '';
    const fieldQuery = this.type == 'field' ? `&field_id=${this.data.id}` : '';
    const sectorQuery =
      this.type == 'sector'
        ? `&field_id=${this.data.father_field}&sector_id=${this.data.id}`
        : '';

    const query =
      currentStatusQuery +
      startDateQuery +
      finishDateQuery +
      userQuery +
      fieldQuery +
      sectorQuery;

    this.loadingInform = true;
    this.informsService.generateInform(query, this.type).subscribe(
      (resp) => {
        this.loadingInform = false;
        this.globalFunction.animation(
          'success',
          `${resp.title}`,
          'Se generó correctamente'
        );
        this.globalFunction.Modal(``, 'hide', {});

        this.validateRequisitions = true;
        this.router.navigateByUrl(`/agricola/informe/${resp.id}`);
      },
      (err) => {
        this.loadingInform = false;
      }
    );
  };

  validateText(type: any) {
    switch (type) {
      case 'agricola':
        return 'home.inform';
      case 'field':
        return 'home.field';
      case 'sector':
        return 'home.sector';
      default:
        return 'home.sector';
    }
  }
}
