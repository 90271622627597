<div class="aside-inner text-white">
  <nav
    class="sidebar"
    sidebar-anyclick-close=""
    [class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')"
  >
    <ul class="sidebar-nav">
      <li class="h-100">
        <app-userblock></app-userblock>
      </li>

      <ng-container *ngFor="let item of menuItems; let i = index">
        <li *ngIf="!item.disabled">
          <a
            title="{{ item.text }}"
            (click)="toggleSubmenuClick(item)"
            [ngClass]="{ 'red-background': item.active }"
            [routerLink]="item.type === 1 ? item.link : null"
            [attr.route]="item.type === 1 ? item.link : null"
          >
            <span
              class="float-right"
              *ngIf="item.alert"
              [ngClass]="item.label || 'badge badge-success'"
              >{{ item.alert }}</span
            >
            <em class="{{ item.icon }}" *ngIf="item.icon"></em>
            <small class="text">{{ item.text | translate }}</small>
          </a>

          <!-- LIST FROM MENU ITEM -->
          <ul
            *ngIf="item.active && item.submenu && !item.disabled"
            class="sidebar-nav sidebar-subnav"
            [ngClass]="{ opening: validateClass(item.active, item.show) }"
            (mouseleave)="mouseleaveActive(i, 'leave')"
          >
            <ng-container
              *ngFor="let subitem of item.submenu; let subMenuIndex = index"
            >
              <li>
                <a
                  [routerLink]="subitem.link"
                  [attr.route]="subitem.link"
                  title="{{ subitem.text }}"
                  (click)="toggleSubmenuClick(subitem)"
                >
                  <span
                    class="float-right"
                    *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'badge badge-success'"
                    >{{ subitem.alert }}</span
                  >
                  <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
                  <span>{{ subitem.text | translate }}</span>
                </a>
              </li>
            </ng-container>
            <li></li>
          </ul>
        </li>
      </ng-container>
    </ul>

    <div class="pos-logout" (click)="logout()">
      <p class="m-0 p-1 d-none-sm">salir</p>
      <em class="fas fa-2x fa-sign-out-alt"></em>
    </div>
  </nav>
</div>

<ng-template #profileModal>
  <div class="modal-header">
    <div class="brand-logo" style="font-size: 20px; padding: 8px">
      <img
        class="img-fluid"
        [src]="'assets/img/logo.png'"
        alt="App Logo"
        style="height: 54px"
        (error)="('assets/img/logo.png')"
      />
      {{ "profile.title" | translate }}
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="GlobalFunction.Modal(profileModal, 'hide', {})"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2">
    <div class="container">
      <app-profile></app-profile>
    </div>
  </div>
</ng-template>
