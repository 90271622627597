import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import { ImageStatic } from 'ol/source';
import { Image } from 'ol/layer';
import { boundingExtent } from 'ol/extent';

import { transform, Projection, transformExtent, fromLonLat } from 'ol/proj.js';

@Component({
  selector: 'app-map-ol',
  templateUrl: './map.html',
  styleUrls: ['./map.scss'],
})
export class MapOpenLayerComponent implements OnInit {
  map = null;
  url = '';

  @Input() center: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['center'].currentValue.length > 0) {
      if (this.map) {
        this.map.updateSize();
      } else {
        this.initilizeMap();
      }
    } else {
    }
  }
  ngOnInit() {}

  initilizeMap() {
    this.map = new Map({
      target: 'map',

      layers: [
        new Tile({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: [-10997148, 4569099],
        zoom: 4,
      }),
    });
    this.addImage();
  }

  addImage = () => {
    let newExtent = [];
    this.center.forEach((element, idx) => {
      if (element.length > 0) {
        newExtent.push(
          element.map((item) =>
            transform(
              [Number(item[0].toFixed(4)), Number(item[1].toFixed(4))],
              'EPSG:4326',
              'EPSG:3857'
            )
          )
        );
      }
    });

    //newExtent = [0, 0, 0, 0]; // [left, bottom, right, top]
    var projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: newExtent[0],
    });

    var StaticImage = new Image({
      source: new ImageStatic({
        attributions: 'Agronodo',
        url: 'http://api.agromonitoring.com/image/1.0/12061662180/617839b6a81b76f3056878d6?appid=5161799f7a020f4eccfb1df314baec45',
        projection: projection,
        imageExtent: newExtent[0],
      }),
    });

    this.map.addLayer(StaticImage);
    this.map.getView().fit(boundingExtent(newExtent[0]), this.map.getSize());
  };
}
