<div class="wrapper">
    <!-- top navbar-->
    <app-header class="topnavbar-wrapper"></app-header>
    <!-- sidebar-->
    <app-sidebar [class]='validateToggle()'> </app-sidebar>
    <!-- Main section-->
    <section [class]="validateToggleContent()">
        <div class="m-sm-4 m-md-5 mt-0 mt-md-4">
            <router-outlet></router-outlet>
        </div>
    </section>
    <!-- Page footer-->
    <!--     <footer class="footer-container" app-footer></footer> -->
</div>