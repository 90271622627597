<ng-container *ngIf="item">
  <img
    *ngIf="item.images?.length > 0"
    [src]="item.images[0]?.image"
    style="height: 80px; border-radius: 50%"
    alt=""
  />
  <p class="my-1"><b>Titulo: </b><u>{{ item.title }}</u></p>
  <small>{{ item.datetime | date : " EEEE, MMMM d, y hh:mm:ss " }}</small>

  <div class="center mt-3">
    <button
      *ngIf="item.pathing && !isNumber(item.pathing)"
      class="btn btn-primary btn-sm"
      style="padding: 0px 20px"
      [routerLink]="[
      '/agricola/lote/' +
      item.pathing?.sector?.father_field +
      '/sector/' +
      item.pathing?.sector?.id +
      '/recorrido/' +
      item.pathing?.id +
      '/reporte/' +
      item.id]"
    >
      <small>Ver más</small>
    </button>
  </div>
</ng-container>
