import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Uris } from '../public/Uris';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalFunction } from '../public/globalFunction';

@Injectable({
  providedIn: 'root',
})
export class SupervisorService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}

  register(supervisor: any) {
    return this.http
      .post(
        `${Uris.API_SUPERVISOR}`,
        supervisor,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  listSupervisor(query) {
    return this.http
      .get(`${Uris.API_SUPERVISOR}${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
  getSupervisor(id) {
    return this.http
      .get(
        `${Uris.API_SUPERVISOR}${id}/`,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  delete(id) {
    return this.http
      .delete(
        `${Uris.API_SUPERVISOR}${id}/`,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  edit(supervisor:any, id:string) {
    return this.http
      .patch(
        `${Uris.API_SUPERVISOR}${id}/`,
        supervisor,
        this.GlobalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
}
