import { Injectable } from '@angular/core';
import { Uris } from '../public/Uris';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { GlobalFunction } from '../public/globalFunction';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}

  getProfile() {
    return this.http
      .get(`${Uris.API_USERS}profile/`, this.GlobalFunction.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

}