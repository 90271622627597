<nav aria-label="Page navigation example">
  <ul class="pagination mb-0 center">
    <li class="page-item">
      <button class="btn p-0" (click)="previuspage()" [disabled]="active === 1">
        <a class="page-link page-link-none-bg" aria-label="Previous">
          <em class="fa fa-caret-left"></em>
          <span aria-hidden="true" class="d-none d-sm-block">Anterior</span>
        </a>
      </button>
    </li>
    <li
      class="page-item new-page-item"
      *ngFor="let item of default"
      (click)="getIndex(item)"
      [ngClass]="{ active: item === active }"
    >
      <a class="page-link">{{ item }}</a>
    </li>
    <li class="page-item">
      <button
        class="btn p-0"
        [disabled]="validateTotal(total)"
        (click)="nextPage()"
      >
        <a class="page-link page-link-none-bg" aria-label="Next">
          <span aria-hidden="true" class="d-none d-sm-block">Siguiente</span>
          <em class="fa fa-caret-right"></em>
        </a>
      </button>
    </li>
  </ul>
</nav>
