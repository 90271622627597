import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Uris } from '../public/Uris';
import { GlobalFunction } from '../public/globalFunction';
@Injectable({
  providedIn: 'root',
})
export class SpecialistService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public globalFunction: GlobalFunction
  ) {}

  getSpecialists(query) {
    return this.http
      .get(`${Uris.API_SPECIALIST}${query}`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  getSpecialist(id: any) {
    return this.http
      .get(`${Uris.API_SPECIALIST}${id}/`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  postSpecialist(obj: any, registryType: boolean = true) {
    return this.http
      .post(
        `${Uris.API_SPECIALIST}`,
        obj,
        registryType
          ? this.globalFunction.headers()
          : this.globalFunction.publicHeaders()
      )
      .pipe(
        map((resp) => {
          this.globalFunction.animation(
            'success',
            'Éxito',
            `Usuario creado exitosamente, verifique su correo para establecer contraseña`
          );
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  edit(data: any, id: any) {
    return this.http
      .patch(
        `${Uris.API_SPECIALIST}${id}/`,
        data,
        this.globalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  deleteSpecialist(id: any) {
    return this.http
      .delete(`${Uris.API_SPECIALIST}${id}/`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  getSpecialistAgencies(id) {
    return this.http
      .get(
        `${Uris.API_SPECIALIST}${id}/specialist_agencies/`,
        this.globalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  getMyAgencies() {
    return this.http
      .get(`${Uris.API_SPECIALIST}my_agencies/`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  getMyClients() {
    return this.http
      .get(`${Uris.API_SPECIALIST}my_clients/`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  getSpecialistClients(id) {
    return this.http
      .get(
        `${Uris.API_SPECIALIST}${id}/specialist_clients/`,
        this.globalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  assignClientsToSpecialist(obj) {
    return this.http
      .post(
        `${Uris.API_SPECIALIST}assign_client_to_specialist/`,
        obj,
        this.globalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  removeClientsFromSpecialist(id) {
    return this.http
      .post(
        `${Uris.API_SPECIALIST}${id}/remove_client_from_specialist/`,
        {},
        this.globalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }
}
