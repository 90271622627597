import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedMapService {
  private report: BehaviorSubject<any> = new BehaviorSubject<any>(1);
  public report$: Observable<any> = this.report.asObservable();

  public updateReport(report: any): void {
    this.report.next(report);
  }
}