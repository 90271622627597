<form [formGroup]="inputSearch" (submit)="setQuery()">
  <div class="row" style="gap:10px">
    <ng-container *ngFor="let item of listFilter">
      <div [class]="item.size" *ngIf="item.filter === 'type'">
        <p class="m-0">Tipo</p>
        <select class="form-control" formControlName="type" (change)="setQuery()">
          <option value="">Ambas</option>
          <option value="A">Agrícola</option>
          <option value="T">Técnica</option>
        </select>
      </div>
      <div [class]="item.size" *ngIf="item.filter === 'field_id'">
        <p class="m-0">lote</p>
        <select class="form-control" formControlName="field_id" (change)="setQuery()">
          <option value="">Todos</option>
          <option *ngFor="let item of fields" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      <div [class]="item.size" *ngIf="item.filter === 'is_current'">
        <p class="m-0">Estado</p>
        <select class="form-control" formControlName="is_current" (change)="setQuery()">
          <option value="">Ambos</option>
          <option [value]="'T'" class="form-control">Activo</option>
          <option [value]="'F'" class="form-control">Inactivo</option>
        </select>
      </div>
      <div [class]="item.size" *ngIf="item.filter === 'risk'">
        <p class="m-0">Estado</p>
        <select class="form-control" formControlName="risk" (change)="setQuery()">
          <option value="">Ambos</option>
          <option value="-risk">Más peligrosas</option>
          <option value="risk">Menos peligrosas</option>
        </select>
      </div>
      <div [class]="item.size" *ngIf="item.filter === 'datetime' || item.filter === 'created' ">

        <div class="row">
          <div class="col-12">
            <p class="m-0">Fecha inicio</p>
          </div>
          <div class="col-12 col-md-6 pr-md-0 ">
            <input type="date" class="form-control mr-2 " formControlName="start_date" (change)="setQuery(item.filter)">
          </div>
          <div class="col-12 col-md-6 pr-md-0   mt-2 mt-md-0  ">
            <input type="date" class="form-control " formControlName="finish_date" (change)="setQuery(item.filter)">
          </div>
        </div>
      </div>
      <div [class]="item.size" *ngIf="item.filter === 'alert_type'">
        <span>{{'notification.filter_type' | translate}}</span>
        <div class="form-outline">
          <select formControlName="alert_type" class="form-control" (change)="setQuery()">
            <option value="">{{'notification.all' | translate}}</option>
            <option value="1">{{'notification.notifications' | translate}}</option>
            <option value="10">{{'notification.config' | translate}}</option>
            <option value="11">Permisos cambiados</option>
            <option value="14">{{'notification.limit' | translate}}</option>
            <option value="20">{{'notification.tasks' | translate}}</option>
            <option value="21">Tareas creadas</option>
            <option value="30">{{'notification.incidence' | translate}}</option>
            <option value="31">Recorrido completado</option>
          </select>
        </div>
      </div>
      <div [class]="item.size" *ngIf="item.filter === 'alert_status'">
        <span>{{'notification.filter_status' | translate}}</span>
        <div class="form-outline">
          <select formControlName="alert_status" class="form-control" (change)="setQuery()">
            <option value="">{{'notification.all' | translate}}</option>
            <option value="0">{{'notification.non_seen' | translate}}</option>
            <option value="1">{{'notification.seen' | translate}}</option>
          </select>
        </div>
      </div>
      <div [class]="item.size" *ngIf="item.filter === 'name'">
        <span>Filtro por nombre</span>
        <div>
          <em class="fa-2x mr-2 fas fa-search pos-icon-search" (click)="setQuery()"></em>
          <input class="form-control" type="text" formControlName="name" placeholder="Búsqueda" />
        </div>
      </div>
    </ng-container>

    <!-- <div [class]="type" *ngIf="type">
      <p class="m-0">tipo</p>
      <select class="form-control" formControlName="type" (change)="setQuery()">
        <option value="">selecciona una opcion</option>
        <option value="A">1</option>
        <option value="T">2</option>
      </select>
    </div>
    <div [class]="field_id" *ngIf="field_id">
      <p class="m-0">tipo</p>
      <select class="form-control" formControlName="field_id" (change)="setQuery()">
        <option value="">selecciona una opcion</option>
        <option value="b">1</option>
        <option value="v">2</option>
      </select>
    </div> -->
  </div>
</form>