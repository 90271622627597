import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {  FormControl,  FormGroup } from '@angular/forms';
import { FieldsAgricolaService } from 'src/app/Services/agricola/fields-agricola.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.html',
  styleUrls: ['./filter.scss'],
})
export class FilterComponent implements OnInit {
  @Output('event') event = new EventEmitter();
  inputSearch:  FormGroup;

  @Input() listFilter;

  fields = [];
  constructor(public FieldsAgricolaService: FieldsAgricolaService) {
    this.inputSearch = new  FormGroup({
      type: new  FormControl(''),
      field_id: new  FormControl(''),
      is_current: new  FormControl(''),
      alert_type: new  FormControl(''),
      alert_status: new  FormControl(''),
      name: new  FormControl(''),

      /* ordering */
      risk: new  FormControl(''),
      start_date: new  FormControl(''),
      finish_date: new  FormControl(''),
    });
  }

  ngOnInit() {
    this.getfields();
  }
  setQuery(filter?) {
    const {
      type,
      field_id,
      is_current,
      start_date,
      finish_date,
      risk,
      alert_type,
      alert_status,
      name,
    } = this.inputSearch.value;
    const datetime = `${
      start_date && finish_date
        ? `&${filter}=${start_date}T00:00:00Z,${finish_date}T23:59:59Z`
        : ''
    }`;
    const typeQuery = `${type ? `&type=${type}` : ''}`;
    const fieldQuery = `${field_id ? `&field_id=${field_id}` : ''}`;
    const is_currentQuery = `${is_current ? `&is_current=${is_current}` : ''}`;
    const orderingRiskQuery = `${risk ? `&ordering=${risk}` : ''}`;
    const alert_typeQuery = `${alert_type ? `&type=${alert_type}` : ''}`;
    const alert_statusQuery = `${alert_status ? `&read=${alert_status}` : ''}`;
    const nameQuery = `${name ? `&name=${name}` : ''}`;

    let query =
      typeQuery +
      fieldQuery +
      is_currentQuery +
      datetime +
      orderingRiskQuery +
      alert_typeQuery +
      alert_statusQuery +
      nameQuery;
    this.event.emit(query);
  }

  getfields = () => {
    this.FieldsAgricolaService.listFields().subscribe((item) => {
      this.fields = item;
    });
  };
}
