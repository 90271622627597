import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable()
export class AgronodoGuard  {
  constructor(private location: Location, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = JSON.parse(window.localStorage.getItem('USER'));

    if (user.user_type == 1 || user.user_type == 2 || user.user_type == 3) {
      if (state.url.includes('/admin') && user.user_type == 3) {
        Swal.fire({
          text: 'No tienes permisos para acceder a esta página',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
          width: '250px',
        });
        this.router.navigate(['/agronodo/home']);
        return false;
      } else {
        return true;
      }
    } else {
      this.location.back()
      return false;
    }
  }
}
