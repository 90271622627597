import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Uris } from '../public/Uris';
import { GlobalFunction } from '../public/globalFunction';
@Injectable({
  providedIn: 'root',
})
export class AdminAgronodo {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}

  register(admin) {
    return this.http
      .post(`${Uris.API_AGRONODO}`, admin, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  listAdmin(query: any) {
    return this.http
      .get(`${Uris.API_AGRONODO}${query}`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
  getAdmin(id) {
    return this.http
      .get(`${Uris.API_AGRONODO}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }

  delete(id) {
    return this.http
      .delete(`${Uris.API_AGRONODO}${id}/`, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
  edit(admin, id) {
    return this.http
      .patch(`${Uris.API_AGRONODO}${id}/`, admin, this.GlobalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.GlobalFunction.validateError(err);
        })
      );
  }
}
