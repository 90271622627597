import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Uris } from '../public/Uris';
import { GlobalFunction } from '../public/globalFunction';

@Injectable({
  providedIn: 'root',
})
export class GlobalServices {
  constructor(
    public http: HttpClient,
    public router: Router,
    public GlobalFunction: GlobalFunction
  ) {}
  ngOnInit(): void {}


}
