import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalFunction } from '../public/globalFunction';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Uris } from '../public/Uris';

@Injectable({
  providedIn: 'root',
})
export class AgricolaAgronodo {
  constructor(
    public http: HttpClient,
    public router: Router,
    public globalFunction: GlobalFunction
  ) {}
  ngOnInit(): void {}

  register(agricola, registryType: boolean = true) {
    return this.http
      .post(
        `${Uris.API_AGRICOLA}`,
        agricola,
        registryType
          ? this.globalFunction.headers()
          : this.globalFunction.publicHeaders()
      )
      .pipe(
        map((resp) => {
          this.globalFunction.animation(
            'success',
            'Éxito',
            `Usuario creado exitosamente, verifique su correo para establecer contraseña`
          );
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }

  listaAgricola(query) {
    return this.http
      .get(`${Uris.API_AGRICOLA}${query}`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }
  getAgricola(user) {
    return this.http
      .get(`${Uris.API_AGRICOLA}${user}/`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          return this.globalFunction.validateError(err);
        })
      );
  }
  delete(id) {
    return this.http
      .delete(`${Uris.API_AGRICOLA}${id}/`, this.globalFunction.headers())
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          this.globalFunction.animation(
            'success',
            'Éxito',
            `Usuario eliminado exitosamente`
          );
          return this.globalFunction.validateError(err);
        })
      );
  }
  edit(agricola, id) {
    return this.http
      .patch(
        `${Uris.API_AGRICOLA}${id}/`,
        agricola,
        this.globalFunction.headers()
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err: any) => {
          this.globalFunction.animation(
            'success',
            'Éxito',
            `Usuario editado exitosamente`
          );
          return this.globalFunction.validateError(err);
        })
      );
  }
}
