import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  imports: [
    ComponentsModule,
    RouterModule,
  ],
  declarations: [
    LayoutComponent,
    FooterComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
  ],
  exports: [LayoutComponent],
  providers: [],
})
export class LayoutModule {}
