import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  declarations: [],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
    };
  }
}
