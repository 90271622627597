import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.html',
  styleUrls: ['./image-cropper.scss'],
})
export class ImageCropperComponent implements OnInit {
  @Input('url') url: any = '';
  @Output('setbase64') setbase64 = new EventEmitter();
  @Input('type') type = 'base64';
  @Input('size') size = 1;
  @Input('rounded') rounded = true;
  @Input('fontPage') fontPage = false;

  containWithinAspectRatio = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  file: any;

  defaultImage: String = 'assets/img/logo.png';
  showCropp = false;
  canvasRotation = 0;
  scale = 0;
  transform: ImageTransform = {
    scale: 0,
    flipH: false,
    flipV: false,
  };

  constructor() {}

  ngOnInit(): void {}

  cropperReady(event) {}

  fileChangeListener(event) {
    this.file = event.target.files[0];
    this.imageChangedEvent = event;
    this.showCropp = true;
  }

  imageCropped = async (event: ImageCroppedEvent) => {
    console.log(event);
    let base64 = await event.base64;
    this.url = base64;

    if (event.base64) {
      this.setbase64.emit(
        this.type === 'base64' ? event.base64 : this.dataURLtoFile(event.base64)
      );
    } else {
      this.setbase64.emit(event.blob);
    }
  };

  loadImageFailed() {}

  LoadedImage(event) {}

  dataURLtoFile(image) {
    console.log(image);

    const split = image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  checkImage = async (url) => {
    const res = await fetch(url);
    const buff = await res.blob();

    return buff.type.startsWith('image/');
  };

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  imageLoaded() {}

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  returnImage() {
    return this.url ? this.url : this.defaultImage;
  }
}
