import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-incidence-card',
  templateUrl: './incidence-card.component.html',
  styleUrls: ['./incidence-card.component.scss'],
})
export class IncidenceCardComponent implements OnInit {
  private _incidence: any;

  @Input('detail') detail;
  @Input() set incidence(incidence: any) {
    if (incidence) {
      this._incidence = incidence;
    }
  }

  agricultural: boolean = true;
  agriculturalIncidenceItems: any;

  constructor() {}

  get incidence(): any {
    return this._incidence;
  }

  ngOnInit(): void {}

  ngOnChanges() {
    this.typeIncidence();

    this.agriculturalIncidenceItems = [
      {
        label: 'incidences.fruit',
        value: this.incidence.agricultural_incidence?.fruit,
      },
      {
        label: 'incidences.plant',
        value: this.incidence.agricultural_incidence?.plant,
      },
      {
        label: 'incidences.soil',
        value: this.incidence.agricultural_incidence?.ground,
      },
    ];
  }

  typeIncidence() {
    if (this.incidence.technical_incidence) {
      this.agricultural = false;
    } else if (this.incidence.agricultural_incidence) {
      this.agricultural = true;
    }
  }

  color(value) {
    switch (value) {
      case 1:
        return '#87c933';
      case 2:
        return '#FFFF00';
      case 3:
        return '#cb3234';
      default:
        return '#cb3234';
    }
  }

  imagePlant(fruit, plant, ground) {
    if (fruit && plant && ground) {
      return `assets/img/plant/${fruit}_${plant}_${ground}.png`;
    }
    return '';
  }
}
